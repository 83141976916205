// OptionalField.tsx
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeOptionalField, setCheckoutFormErrors, updateOptionalField } from '../../redux/slices/checkoutBuilderSlice';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { TfiTrash } from 'react-icons/tfi';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { TiWarningOutline } from 'react-icons/ti';

interface OptionalFieldProps {
    index: number;
    label: string;
    description: string;
    type: string;
    required: boolean;
    characterLimits?: number;
    options?: CheckBoxOption[] | ListOption[];
    displayAsRadiobutton?: boolean;
}

type CheckBoxOption = {
    value: string;
};

type ListOption = {
    text: string;
    value: string;
};

const OptionalField: React.FC<OptionalFieldProps> = ({ index, label, description, type, required, options }) => {

    const [checkBoxOptions, setCheckBoxOptions] = useState<CheckBoxOption[]>([]);

    const [droplistOptions, setDropListOptions] = useState<ListOption[]>([]);

    const [fieldErrors, setFieldErrors] = useState({
        label: "",
        description: "",
        characterLimits: "",
        options: false
    });

    const { checkoutFormErrors, editMode: isEditMode } = useSelector((state: any) => state.checkoutBuilder);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isEditMode && options) {
            const adaptedOptions = options;

            setCheckBoxOptions((prevOptions: any) => {
                const updatedOptions = [...prevOptions, ...adaptedOptions];

                return updatedOptions;
            });
        }
    }, [isEditMode]);

    const handleRemove = () => {
        dispatch(removeOptionalField(index));
    };

    const handleUpdate = (updatedField: Partial<OptionalFieldProps>) => {

        //const regexOnlyText = /^[a-zA-Z\s]+$/
        const regexOnlyText = /^[\p{L}\s]+$/u;

        const fieldKey = Object.keys(updatedField)[0];
        const fieldValue = Object.values(updatedField)[0];

        let updatedFieldErrors = { ...fieldErrors };
        checkBoxOptions.map(option =>
            typeof option === 'object' && option !== null ? option.value : option
        );
        dispatch(updateOptionalField({
            index, field: {
                ...{
                    label, description, type, required, options: checkBoxOptions.map(option =>
                        typeof option === 'object' && option !== null ? option.value : option
                    )
                }, ...updatedField
            }
        }));
        dispatch(setCheckoutFormErrors({ key: "optionalFields", value: false }))

        if (fieldKey === "label") {
            updatedFieldErrors = { ...updatedFieldErrors, label: "" };
            if (fieldValue == "") {
                //setFieldErrors(prevState => ({ ...prevState, label: "The label should not be empty" }));
                updatedFieldErrors = { ...updatedFieldErrors, label: "The label should not be empty" };
            }
            else if (typeof fieldValue == "string" && !regexOnlyText.test(fieldValue)) {
                updatedFieldErrors = { ...updatedFieldErrors, label: "The title should not contain numbers or special chars" };
            }
        } else if (fieldKey === "description") {
            updatedFieldErrors = { ...updatedFieldErrors, description: "" };
            if (fieldValue == "") {
                updatedFieldErrors = { ...updatedFieldErrors, label: "The description should not be empty" };
            }

        } else if (fieldKey === "type") {
            console.log("Field value ::::: ")

        } else if (fieldKey === "characterLimits") {
            console.log()
        } else if (fieldKey === "options") {
            console.log("fieldKey ::", fieldKey)
            console.log("checkBoxOptions.length : ", checkBoxOptions.length)
            if (checkBoxOptions.length == 0) dispatch(setCheckoutFormErrors({ key: "optionalFields", value: true }))
        }
        setFieldErrors(updatedFieldErrors);

        if (Object.values(updatedFieldErrors).some(error => error !== "" && error !== false)) {
            dispatch(setCheckoutFormErrors({ key: "optionalFields", value: true }))
        }
    };

    const handleAddCheckboxOption = () => {
        //setCheckBoxOptions((prevOptions: any) => [...prevOptions, '']);
        setCheckBoxOptions((prevOptions: any) => {
            const updatedOptions = [...prevOptions, ''];

            /* dispatch(updateOptionalField({
                index,
                field: { label, description, type, required, options: updatedOptions }
            })); */

            handleUpdate({ options: updatedOptions });

            return updatedOptions;
        });
    };

    const handleRemoveCheckboxOption = (indexToRemove: number) => {
        //setCheckBoxOptions((prevOptions) => prevOptions.filter((_, index) => index !== indexToRemove));
        setCheckBoxOptions((prevOptions) => {
            const newOptions = prevOptions.filter((_, index) => index !== indexToRemove);
            handleUpdate({ options: newOptions });
            return newOptions;
        });
    };

    const handleUpdateCheckboxOption = (index: any, value: any) => {
        /* setCheckBoxOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[index] = value;
            return newOptions;
        });
        handleUpdate({ options: checkBoxOptions }); */
        const radioInput = document.querySelector('input[name="optionfield-display-as-radio"]') as HTMLInputElement | null;
        const isDisplayAsRadioChecked = radioInput ? radioInput.checked : false;

        setCheckBoxOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[index] = value;
            handleUpdate({ options: newOptions, displayAsRadiobutton: isDisplayAsRadioChecked });
            return newOptions;
        });
    };

    const handleAddDropListOption = () => {
        setDropListOptions((prevOptions: any) => [...prevOptions, { text: "", value: "" }]);
    };

    const handleUpdateListOption = (index: number, updatedOption: Partial<ListOption>) => {
        setDropListOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[index] = { ...newOptions[index], ...updatedOption };
            return newOptions;
        });

        handleUpdate({ options: droplistOptions });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUpdate({ required: e.target.checked });
    };

    /* const validateText = (value: any) => {
        if (value === '') {
            setFieldErrors({ ...fieldErrors, textError: "The label must not be empty" });
        }

        else if (isNaN(value)) {

        }
    }; */

    const validateCheckboxOptions = (options: CheckBoxOption[]) => {
        return options.map(option => option.value.trim() === '' ? 'Option cannot be empty' : '');
    };

    return (
        <div key={index}>
            <Row className="mt-2 d-flex align-items-center justify-content-between">
                <Col>
                    <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>
                        Champ {index + 1}
                    </span>
                </Col>
                <Col className="text-end">
                    <a
                        onClick={handleRemove}
                        title="delete"
                        style={{ cursor: "pointer", marginLeft: "auto" }}
                    >
                        <FaTrashAlt color="#B42C2C" size={20} />
                    </a>
                </Col>
            </Row>


            <Row className='mt-2' >
                <Col sm={4}>
                    <FormGroup>
                        <Label check style={{ fontWeight: "bold", marginTop: "10px" }} for="optional_field_label" className="d-block">
                            Libellé
                            <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span>
                        </Label>

                        <Input
                            id="optional_field_label"
                            name="optional_field_label"
                            type="text"
                            value={label}
                            onChange={(e) => handleUpdate({ label: e.target.value })}
                            valid={label != "" && fieldErrors.label == ""}
                            invalid={fieldErrors.label != ""}
                            className='mt-2'
                            style={{ height: "38px" }}
                        />

                    </FormGroup>
                </Col>
                <Col sm={8}>
                    <FormGroup>
                        <Label check style={{ fontWeight: "bold", marginTop: "10px", }} for="optional_field_description" className="d-block">
                            Description
                            <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span>
                        </Label>

                        <Input
                            id="optional_field_description"
                            name="optional_field_description"
                            type="textarea"
                            rows="1"
                            value={description}
                            onChange={(e) => handleUpdate({ description: e.target.value })}
                            valid={description != "" && fieldErrors.description == ""}
                            invalid={fieldErrors.description != ""}
                            className='mt-2'
                        />
                    </FormGroup>

                </Col>
            </Row>
            {
                fieldErrors.label && <Row className='' style={{ display: 'flex', alignItems: 'center' }}>
                    <span className='text-danger' style={{ fontSize: "12px", fontWeight: "400" }}><TiWarningOutline color='#DF1B41' /> {fieldErrors.label}</span>
                </Row>
            }
            {
                fieldErrors.description && <Row className='' style={{ display: 'flex', alignItems: 'center' }}>
                    <span className='text-danger' style={{ fontSize: "12px", fontWeight: "400" }}><TiWarningOutline color='#DF1B41' /> {fieldErrors.description}</span>
                </Row>
            }

            <Row className='my-2 d-flex'>
                <Col sm={4} className="d-flex align-items-end">
                    <FormGroup className="">
                        <Label check style={{ fontWeight: "bold" }} for="optional_field_type" className="d-block">
                            Type
                        </Label>
                        <Input
                            id="optional_field_type"
                            name="optional_field_type"
                            type="select"
                            onChange={(e) => {
                                if (e.target.value == "dropdownlist") handleUpdate({ type: e.target.value, displayAsRadiobutton: false })
                                else handleUpdate({ type: e.target.value })
                            }}
                            value={type}
                            className='mt-2'
                        >
                            <option value="text">Text</option>
                            <option value="checkbox">Checkbox</option>
                            <option value="dropdownlist">Dropdownlist</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm={4} className="d-flex align-items-end">

                    {
                        isEditMode ?
                            <FormGroup
                                check
                                inline
                                className='mb-3'
                            >
                                <Input type="checkbox" onChange={handleCheckboxChange} checked={required} />
                                <Label check>
                                    Required
                                </Label>
                            </FormGroup>
                            :
                            <FormGroup
                                check
                                inline
                                className='mb-3'
                            >
                                <Input type="checkbox" onChange={handleCheckboxChange} />
                                <Label check>
                                    Required
                                </Label>
                            </FormGroup>
                    }
                </Col>


            </Row>
            {type === 'text' && (
                <>
                    <Row className="d-flex justify-content-between align-items-center">
                        <Col className='' sm={6}>
                            <Label for="optionfield-characters-limit">Set Character Limits:</Label>
                        </Col>
                        <Col sm={4} className='text-end'>
                            <input
                                name="optionfield-characters-limit"
                                type="number"
                                style={{ maxWidth: "50px", padding: "2px" }}
                                min="1"
                                max="999"
                                //value={characterLimits}
                                onChange={(e) => handleUpdate({ characterLimits: parseInt(e.target.value) })}
                            />
                        </Col>
                    </Row>


                </>
            )}

            {type === 'checkbox' && (
                <>
                    <Row className="justify-content-between mb-4">
                        <Col sm={6}>
                            <Label for="optionfield-options">Specify the options :</Label>
                        </Col>
                        <Col sm={6} className='text-end' style={{ paddingRight: "0px" }}>
                            <Button
                                style={{ paddingLeft: "2px" }}
                                className="outlined-button-with-icon mx-2"
                                color="light"
                                onClick={handleAddCheckboxOption}
                            >
                                <FaPlus className="mx-2" />
                                Add option
                            </Button>
                        </Col>
                    </Row>

                    {checkBoxOptions.map((option: any, index) => (
                        <Row key={index} className='my-1'>
                            <Col sm={3} className="d-flex align-items-center">
                                <Label for="">Option {index + 1}</Label>
                            </Col>
                            <Col sm={6}>
                                <Input
                                    type="text"
                                    value={option.value || option}
                                    onChange={(e) => handleUpdateCheckboxOption(index, e.target.value)}
                                />
                            </Col>
                            <Col sm={3} className="d-flex align-items-center justify-content-end">
                                <a
                                    onClick={() => handleRemoveCheckboxOption(index)}
                                    className="text-center"
                                    style={{ fontWeight: "500", fontSize: "14px", textDecorationLine: "underline", color: "#213A7D", cursor: "pointer" }}
                                >
                                    <FaTrashAlt color="#B42C2C" size={20} />
                                </a>

                            </Col>
                        </Row>
                    ))}

                </>
            )}

            {type === 'dropdownlist' && (
                <>
                    {/* <Row className='mx-3'>
                        <FormGroup
                            check
                            inline
                        >
                            <Input
                                name="optionfield-display-as-radio"
                                style={{ cursor: "pointer" }}
                                type="checkbox"
                                //checked={displayAsRadiobutton}
                                onChange={(e) => handleUpdate({ displayAsRadiobutton: e.target.checked })}
                            />
                            <Label check style={{ fontWeight: "bold", }} for="optionfield-display-as-radio" className="d-block">
                                Display as Radiobutton:
                            </Label>
                        </FormGroup>
                    </Row> */}
                    <Row className="justify-content-between mb-4">
                        <Col sm={6}>
                            <Label for="optionfield-options">Specify the options :</Label>
                        </Col>
                        <Col sm={6} className='text-end' style={{ paddingRight: "0px" }}>
                            <Button
                                style={{ paddingLeft: "2px" }}
                                className="outlined-button-with-icon mx-2"
                                color="light"
                                onClick={handleAddCheckboxOption}
                            >
                                <FaPlus className="mx-2" />
                                Add option
                            </Button>
                        </Col>
                    </Row>

                    {checkBoxOptions.map((option, index) => (
                        <Row key={index} className='my-1'>
                            <Col sm={3} className="d-flex align-items-center">
                                <Label for="">Option {index + 1}</Label>
                            </Col>
                            <Col sm={6}>
                                <Input
                                    type="text"
                                    value={option.value}
                                    onChange={(e) => handleUpdateCheckboxOption(index, e.target.value)}
                                />
                            </Col>
                            <Col sm={3} className="d-flex align-items-center justify-content-end">
                                <a
                                    onClick={() => handleRemoveCheckboxOption(index)}
                                    className="text-center"
                                    style={{ fontWeight: "500", fontSize: "14px", textDecorationLine: "underline", color: "#213A7D", cursor: "pointer" }}
                                >
                                    <FaTrashAlt color="#B42C2C" size={20} />
                                </a>

                            </Col>
                        </Row>
                    ))}

                    {/* <label>Options:</label>
                    {droplistOptions.map((option, index) => (
                        <div key={index}>
                            <input
                                type="text"
                                value={option.text}
                                onChange={(e) => handleUpdateListOption(index, { text: e.target.value })}
                            />
                            <input
                                type="text"
                                value={option.value}
                                onChange={(e) => handleUpdateListOption(index, { value: e.target.value })}
                            />
                        </div>
                    ))} */}

                    {/* <button
                        onClick={handleAddDropListOption}
                    >
                        Add Option
                    </button> */}
                </>
            )}

            <hr />
        </div>
    );
};

export default OptionalField;