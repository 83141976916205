import React, { CSSProperties, useEffect, useState } from "react";
// ** Images
import edinarPaymeeVisaMc from "../../assets/images/edinar_paymee_visa_mc.png";
import companyLogo from "../../assets/images/image_4.png";
import {
  getPaylinkDetails,
  getPaylinkDetailsByHash,
} from "../../services/api/panormix";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaDesktop, FaDonate, FaInfoCircle, FaList, FaLock, FaMobile, FaPlus, FaSyncAlt, FaTrash, FaTrashAlt } from "react-icons/fa";
import logo from "../../assets/images/logo_paymee.png";
import { Provider, useDispatch, useSelector } from "react-redux";
import success from '../../assets/images/success.gif'
import failure from '../../assets/images/failure.gif'
import { ThunkDispatch } from "@reduxjs/toolkit";
import { addProductToCheckoutOrder, chooseDeliveryOption, initAdditionalFields, initAvailableProducts, removeProductFromCheckoutOrder, savePhotoDonation, setAvailableDeliveryOption, setCheckoutFormErrors, toggleExtraToCheckoutOrder, toggleTicketToCheckoutOrder, updataeActualStepProg, updateActiveTab, updateChekoutFor, updateChekoutType, updateMerchantDetails, updateEventQuantity, updateOptionValue, updatePayerInfos, updatePaylinkDetailsField, updateProductQuantity, updateResultedAmountDonation, updateExtraQuantity, switchProductToCheckoutOrder } from "../../redux/slices/paymentCheckoutSlice";
import { DotLoader } from "react-spinners";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Badge, Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Popover, PopoverBody, Progress, Row, Spinner, TabContent, TabPane, Table, ListGroup, ListGroupItem, Alert } from 'reactstrap';
import IconToExplain from "../../components/ui/IconToExplain";
import { TiWarningOutline } from "react-icons/ti";
import PButton from "../../components/form/button/Button";
import successImg from '../../assets/images/success.gif';
import { MdErrorOutline, MdProductionQuantityLimits } from "react-icons/md";
import emptyProductImg from '../../assets/images/empty_product_img.png';
import { FaRegClone, FaShop } from "react-icons/fa6";
import notYetPaymentAvailable from "../../assets/images/empty-transactions.svg";
import { loadPayment } from "../../services/api/gateway";
import { IoTicketOutline } from "react-icons/io5";
import { CiDeliveryTruck } from "react-icons/ci";
import "./PaylinkCheckout.css";
import QtyInput from "../../components/form/input/QuantityInput";
import { BsTrash } from "react-icons/bs";

import imgPlaceholder from "../../assets/images/placeholder_img_test.svg";
import imgPlaceholder2 from "../../assets/images/placeholder_img_test_2.svg";
import emptyMerchantImg from '../../assets/images/empty_merchant_img.svg';
import Select from "react-select";
import { hasAnyErrors, isObjectEmpty } from "../../utils/helper";
import PayerContactForm from "./PayerContactForm";
import EventItem from "./EventItem";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import FloatingLabelSelect, { SelectChangeEvent } from '@mui/material/Select';
import store from '../../redux/store';

const PaylinkCheckout = () => {
  const [paylinkData, setPaylinkData] = useState<any>({});
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const { hash } = useParams();
  const profile = useSelector((state: any) => state.profile);
  const payment_checkout = useSelector((state: any) => state.paymentCheckout);
  const base_img_url = process.env.REACT_APP_BASED_IMAGE_URL;
  const [paylinkPaied, setPaylinkPaied] = useState(false)
  const [paylinkExpired, setPaylinkExpired] = useState(false)
  const [paylinkOrderNum, setPaylinkOrderNum] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);

  //const [actualStep, setActualStep] = useState(payment_checkout.actualStep);
  //const [actualProgress, setActualProgress] = useState(payment_checkout.actualProgress);

  const [showLoadPaymentLoader, setShowLoadPaymentLoader] = useState(false);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const baseImgUrl = process.env.REACT_APP_BASED_IMAGE_URL;

  const handleProductClick = (productId: any) => {
    setSelectedProductId(productId);
    dispatch(switchProductToCheckoutOrder({ p_id: productId }))
  };

  const styles = {
    control: (css: any) => ({
      ...css,
      width: "100%",
      height: "40px",
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "40px",
    }),


    input: (provided: any, state: any) => ({
      ...provided,
      // margin: "0px",
      marginTop: "-5%",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "40px",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      marginTop: "-2%",

    })
  };

  const groupBadgeStyles: CSSProperties = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const formatGroupLabel = (data: any) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      //if (event.origin !== 'https://myorigind') return;

      if (event.data.type === 'PAYMENT_SUCCESS') {
        dispatch(updataeActualStepProg({ step: 3, prog: 100 }));
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch]);

  useEffect(() => {
    getPaylinkDetailsByHash(hash)
      .then((res) => {
        if (hash) {
          localStorage.setItem("sign", hash);
        }
        /* if (Object.prototype.hasOwnProperty.call(res.data, 'paylink')) {
          dispatch(updateChekoutFor(res.data.paylink.type))
        } else {
          console.log("error founnddn")
        } */
        const paylink = res.data.paylink;

        if (paylink) {
          if (paylink.redirect_url) {
            localStorage.setItem("website", paylink.redirect_url)
          } else {
            localStorage.setItem("website", paylink.vendor_details.website)
          }

          dispatch(updateChekoutFor(paylink.type))

          if (paylink.type == "paylink") {
            dispatch(updateChekoutType("ordinary"))
          } else {
            dispatch(updateChekoutType(paylink.checkout_type));
            dispatch(initAdditionalFields(paylink.additional_fields));
            if (paylink.checkout_type === "sale") {
              dispatch(updatePaylinkDetailsField({ key: 'title', value: paylink.title }));
              dispatch(updatePaylinkDetailsField({ key: 'description', value: paylink.description }));
              dispatch(initAvailableProducts(paylink.products));
              dispatch(setAvailableDeliveryOption(paylink.delivery));
            } else if (paylink.checkout_type === "event") {
              dispatch(initAvailableProducts([paylink.event]));
            } else if (paylink.checkout_type === "donation") {
              dispatch(updatePaylinkDetailsField({ key: 'title', value: paylink.title }));
              dispatch(updatePaylinkDetailsField({ key: 'description', value: paylink.description }));
              dispatch(savePhotoDonation(paylink.picture))
            } else if (paylink.checkout_type === "subscription") {
              dispatch(updatePaylinkDetailsField({ key: 'title', value: paylink.title }));
              dispatch(updatePaylinkDetailsField({ key: 'description', value: paylink.description }));
              dispatch(initAvailableProducts(paylink.subscriptions));
            }

          }
          dispatch(updateMerchantDetails(paylink.vendor_details));
        }
        setPaylinkData(paylink);
        const formUrl = res.data.cb_transaction.form_url;
        setPaymentDetails((prevState: any) => ({
          ...prevState,
          formUrl: formUrl,
        }));
        localStorage.setItem(
          "payment_order",
          res.data.cb_transaction.order_id,
        );
      })
      .catch((err: any) => {
        if (err.response) {
          if (err.response.status === 400) {
            console.log("err.response :", err.response.data.error_code)
            if (err.response.data.error_code === "ERR_PLEXP") {
              setPaylinkExpired(true)
            }
            if (err.response.data.error_code === "ERR_PLAP") {
              setPaylinkPaied(true)

            }
          } else if (err.response.status === 500) {
            toast.error("Request Error: Error occured while trying to login");
          }
        } else if (err.request) {
          toast.error("Network Error : no response from server");
        }
      });
  }, []);

  const handleAddAnotherProductToOrder = (p_id: any, p_index: any) => {
    dispatch(addProductToCheckoutOrder({ p_id, p_index }))
  }

  const handleProductQuantityChange = (productIndex: any, qty: any) => {
    dispatch(updateProductQuantity({ productIndex, quantity: qty }));
  };

  const handleDeleteProductFromOrder = (p_id: any, index: any) => {
    dispatch(removeProductFromCheckoutOrder({ p_id: p_id, index }));
  }

  const handleCheckoutProductOptionSelect = (p_index: any, p_id: any, op_id: any, ov_id: any) => {
    dispatch(updateOptionValue({ p_index: p_index, p_id: p_id, o_id: op_id, ov_id: ov_id, qty: 0 }));
  }

  const handleOptionQuantityChange = (p_id: any, ov_id: any, qty: any) => {
    dispatch(updateEventQuantity({ p_id: p_id, ov_id: ov_id, qty: qty }));
  };

  const handleExtrasQuantityChange = (p_id: any, ov_id: any, qty: any) => {
    dispatch(updateExtraQuantity({ p_id: p_id, ov_id: ov_id, qty: qty }));
  };

  const handleLoadPayment = () => {
    let payload;
    switch (payment_checkout.paylinkType) {
      case "sale":
        payload = {
          "paylink_id": localStorage.getItem("sign"),
          "products": payment_checkout.checkoutOrder.products,
          "total_amount": payment_checkout.checkoutOrder.resultedAmount,
          "paylink_details": payment_checkout.payerInfos,
          "delivery": payment_checkout.checkoutOrder.delivery
        }

        break;
      case "event":
        payload = {
          "paylink_id": localStorage.getItem("sign"),
          "products": payment_checkout.checkoutOrder.products,
          "extras": payment_checkout.checkoutOrder.extras,
          "total_amount": payment_checkout.checkoutOrder.resultedAmount,
          "paylink_details": payment_checkout.payerInfos
        }
        break;
      case "donation":
        payload = {
          "paylink_id": localStorage.getItem("sign"),
          "products": payment_checkout.checkoutOrder.products,
          "total_amount": payment_checkout.checkoutOrder.resultedAmount,
          "paylink_details": payment_checkout.payerInfos
        }
        break;
      case "subscription":
        payload = {
          "paylink_id": localStorage.getItem("sign"),
          "products": payment_checkout.checkoutOrder.products,
          "total_amount": payment_checkout.checkoutOrder.resultedAmount,
          "paylink_details": payment_checkout.payerInfos
        }
        break;
      default:
        return;
    }

    setShowLoadPaymentLoader(true);
    loadPayment(payload)
      .then(res => {
        setTimeout(() => {
          setShowLoadPaymentLoader(false);
        }, 2000);
        const formUrl = res.data.cb_transaction.form_url;
        dispatch(updataeActualStepProg({ step: 2, prog: 50 }))
        //setActualStep(2)
        //setActualProgress(50)
        setPaymentDetails((prevState: any) => ({
          ...prevState,
          formUrl: formUrl,
        }));
        localStorage.setItem(
          "payment_order",
          res.data.cb_transaction.order_id
        );
        setPaylinkOrderNum(res.data.cb_transaction.order_num);
      })
      .catch((err: any) => {
        setTimeout(() => {
          setShowLoadPaymentLoader(false);
        }, 2000);
        if (err.response) {
          if (err.response.status === 400) {
            console.log("err.response :", err.response.data.error_code)
            if (err.response.data.error_code === "ERR_PLEXP") {
              setPaylinkExpired(true)
            }
            if (err.response.data.error_code === "ERR_PLAP") {
              setPaylinkPaied(true)

            }
          } else if (err.response.status === 500) {
            toast.error("Request Error: Error occured while trying to login");
          }
        } else if (err.request) {
          toast.error("Network Error : no response from server");
        }
      });

  };
  const prevStep = () => {
    if (payment_checkout.actualStep > 1) {
      dispatch(updataeActualStepProg({ step: payment_checkout.actualStep - 1, prog: payment_checkout.actualProgress - 25 }))
      //setActualStep(actualStep - 1);
      //setActualProgress(actualProgress - 25);
    }
  };


  const handleDeliveryChoosed = (event: any) => {
    event.stopPropagation();
    if (event.target.type === "checkbox") {
      if (event.target.checked) { dispatch(chooseDeliveryOption(parseInt(event.target.value))); } else { dispatch(chooseDeliveryOption(null)); }
    } else {
      dispatch(chooseDeliveryOption(parseInt(event.target.value)));
    }
  };

  const handlePayerInfosChange = (event: any) => {
    const { name, value, checked } = event.target;
    const regexOnlyText = /^[a-zA-Z\s]+$/
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const phoneNumberRegex = /^\d{8,8}$/;
    const allowOnlyDigitsRegex = /^\d+$/;

    let key_name = "";
    let updatedValue = value;

    if (name === "payer-firstname") {
      key_name = "firstname"
      dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
      if (value == "") {
        dispatch(setCheckoutFormErrors({ key: key_name, value: 'The fristname should not be empty' }))
      }
      else if (!regexOnlyText.test(value)) {
        dispatch(setCheckoutFormErrors({ key: key_name, value: 'The firstname should not contain numbers or special chars' }))
      }

    }

    else if (name === "payer-lastname") {
      key_name = "lastname"
      dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
      if (value == "") {
        dispatch(setCheckoutFormErrors({ key: key_name, value: 'The lastname should not be empty' }))
      }
      else if (!regexOnlyText.test(value)) {
        dispatch(setCheckoutFormErrors({ key: key_name, value: 'The lastname should not contain numbers or special chars' }))
      }

    }

    else if (name === "payer-email") {
      key_name = "email"
      dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
      if (value == "") {
        dispatch(setCheckoutFormErrors({ key: key_name, value: 'The email should not be empty' }))
      }
      else if (!emailRegex.test(value)) {
        dispatch(setCheckoutFormErrors({ key: key_name, value: 'The email is not a valid' }))
      }
    }

    else if (name === "payer-phone") {
      key_name = "phone"

      updatedValue.replace(/[^0-9]/g, '')

      dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
      if (updatedValue == "") {
        dispatch(setCheckoutFormErrors({ key: key_name, value: 'The phone should not be empty' }))
      }
      else if (!phoneNumberRegex.test(updatedValue)) {
        dispatch(setCheckoutFormErrors({ key: key_name, value: 'The phone is not a valid' }))
      }
    }

    else if (name === "payer-address") {
      key_name = "address"
      dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));
      if (value == "") {
        dispatch(setCheckoutFormErrors({ key: key_name, value: 'The address should not be empty' }))
      }
    }
    else {
      key_name = name.split('-')[1];
      dispatch(setCheckoutFormErrors({ key: key_name, value: '' }));

      const thefield = payment_checkout.additionalFields.find((field: any) => field.label.replace(/\s+/g, "_") === key_name);
      if (thefield) {
        const { label: field_label, field_type, required: field_required, options: field_options } = thefield;

        if (field_type === "text" || field_type === "dropdownlist") {
          if (field_required && value == "") {
            dispatch(setCheckoutFormErrors({ key: key_name, value: `The ${field_label} should not be empty` }))
          }
        } else if (field_type === "checkbox") {
          const oldCheckedList = payment_checkout.payerInfos[key_name];
          updatedValue = checked ? [...oldCheckedList, value] : oldCheckedList.filter((op: any) => op !== value);
          if (field_required && updatedValue.length === 0) dispatch(setCheckoutFormErrors({ key: key_name, value: `The ${field_label} should not be empty` }))
        }
      }
    }
    dispatch(updatePayerInfos({ [key_name]: updatedValue }));
  };

  return (
    <>
      <div
        className="wrapper"
        style={{ paddingTop: "5%", paddingBottom: "5%" }}
      >
        <div className="container">
          <div
            className="row"
            style={{
              backgroundColor: "white",
              boxShadow: "0px 16px 60px 20px rgba(186, 203, 248, 0.15)",
            }}
          >
            {
              payment_checkout.checkoutFor == "paylink" ? (
                <div className="col-md-12 col-sm-12">
                  <div>
                    <div className="card-body">
                      {
                        (!paylinkPaied && !paylinkExpired) && (<>
                          <div className="row mt-5 mx-4">
                            <div className="col-md-6" style={{ fontSize: "20px" }}>
                              {
                                paylinkData.vendor_details && paylinkData.vendor_details.logo ? (<>
                                  <img
                                    src={paylinkData && base_img_url + "/media/" + paylinkData.vendor_details.logo}
                                    width="50px"
                                    height="50px"
                                    style={{ float: "left", borderRadius: "25px" }}
                                  /></>) : (<></>)
                              }

                              <span
                                className="merchant_name"
                                style={{ float: "left", marginLeft: "15px", marginTop: "10px", fontWeight: "600", fontFamily: "Inter" }}
                              >
                                {paylinkData.vendor_details && paylinkData.vendor_details.company}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <span
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                >
                                  Description
                                </span>
                              </div>
                              <div className="row">
                                <span
                                  style={{ fontSize: "16px", textAlign: "right" }}
                                >
                                  {paylinkData && paylinkData.description}
                                </span>
                              </div>
                            </div>
                          </div></>)
                      }
                      {
                        (!paylinkPaied && !paylinkExpired) && (<>        <div className="row j-c-center">
                          <div
                            className="col-xs-12"
                            style={{ fontSize: "16px", color: "#3D66D2", fontWeight: "bold", fontFamily: "Inter", textAlign: "center" }}
                          >
                            Montant à payer
                          </div>
                        </div>
                          <div className="row j-c-center">
                            <div
                              className="col-xs-12"
                              style={{
                                fontSize: "35px",
                                fontWeight: "bold",
                                alignItems: "center",
                                textAlign: "center",
                                color: "#3D66D2",
                              }}
                            >
                              {paylinkData.amount} <small>TND</small>
                            </div>
                          </div></>)
                      }
                      <div className="row j-c-center" style={{ display: "none" }}>
                        <div className="col-xs-12 mt-20">
                          <button
                            className="rounded payment-btn"
                            data-toggle="modal"
                            data-target="#modal-payment"
                            data-backdrop="static"
                            data-keyboard="false"
                          >
                            <span
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                            >
                              Accéder au paiement
                            </span>
                            <img
                              src={edinarPaymeeVisaMc}
                              alt="Paymee Visa MasterCard"
                            />
                          </button>
                          <form
                            method="post"
                            action="https://app.paymee.tn/gateway/b2cb7bbb20be7f04d3e81fbbc9974423/"
                          >
                            <input
                              type="hidden"
                              name="url_ok"
                              value="https://paylink.tn/3103/check"
                            />
                            <input
                              type="hidden"
                              name="url_ko"
                              value="https://paylink.tn/failure"
                            />
                            <button className="rounded payment-btn">
                              <span
                                style={{ fontSize: "18px", fontWeight: "bold" }}
                              >
                                Accéder au paiement
                              </span>
                              <img
                                src={edinarPaymeeVisaMc}
                                alt="Paymee Visa MasterCard"
                              />
                            </button>
                          </form>
                        </div>
                      </div>
                      {
                        paylinkPaied && (<>
                          <div className="text-center d-flex justify-content-center mt-5">
                            <img src={success} width={300} height={300} />

                          </div>
                          <div className="text-center" style={{
                            marginTop: "6%",
                            fontSize: "30px",
                            color: "#0E6245",
                            fontWeight: 600
                          }}>Le paiement est déjà fait


                          </div></>)
                      }
                      {
                        paylinkExpired && (<>
                          <div className="text-center">
                            <img src={failure} width={300} height={300} />

                          </div>
                          <div className="text-center" style={{
                            marginTop: "8%",
                            fontSize: "30px",
                            color: "#B42C2C",
                            fontWeight: 600
                          }}>Le paiement est expiré


                          </div></>)
                      }
                      <iframe
                        allowTransparency={true}
                        style={{ backgroundColor: "#FFFFFF" }}
                        id="cb-iframe"
                        src={paymentDetails.formUrl.startsWith('http://') ? paymentDetails.formUrl.replace('http://', 'https://') : paymentDetails.formUrl}
                        width="100%"
                        height="700px"
                      ></iframe>
                      <div className="mt-3 text-center" >
                        <a style={{ fontSize: "14px", color: "#034DA3" }} href=''>Annuler et retourner à la boutique</a>

                      </div>
                      <div className="text-center mt-2">
                        <span><FaLock color="#00FF00" /> <span style={{ color: "#BCBCBC", fontWeight: '400', fontFamily: "Inter" }}>Le payement par carte bancaire est géré par la Société Monétique de Tunisie</span></span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <span style={{ fontSize: "12px" }}>Crée par</span>
                        <img style={{ marginLeft: "15px" }} src={logo} height={25} width={85}></img>
                      </div>
                    </div>
                  </div>
                </div>
              ) : payment_checkout.checkoutFor == "paylink_advanced" ? (
                <>
                  <Card className='border-0' style={{ backgroundColor: "#fff", padding: "0" }}>
                    <CardBody className='' style={{ minHeight: "1200px", padding: "0" }}>
                      <Progress className="progress" value={payment_checkout.actualProgress} />
                      {payment_checkout.actualStep === 1 &&
                        <>
                          {payment_checkout.paylinkType == "sale" ?
                            <>
                              <div className="payment-step d-flex justify-content-end mx-4 my-2">
                                <span className="payment-step-text payment-step-text--gray">Etape {payment_checkout.actualStep}/3</span>
                              </div>
                              <Row className="m-5">
                                <Col xs="auto">
                                  <div className="merchant-logo-container" style={{ border: "2px solid #ddd", borderRadius: "20px", padding: "10px" }}>
                                    <img
                                      className="merchant-logo-img"
                                      src={paylinkData && paylinkData.vendor_details && paylinkData.vendor_details.logo !== "" ? paylinkData && base_img_url + "/media/" + paylinkData.vendor_details.logo : emptyMerchantImg}
                                      width="40px"
                                      height="40px"
                                      style={{ borderRadius: "10px" }}
                                    />
                                  </div>
                                </Col>
                                <Col className="d-flex align-items-end py-2">
                                  <span className="merchant-name" style={{ fontSize: "24px", fontWeight: "600", fontFamily: "Inter", color: "#515151" }}>
                                    {paylinkData.vendor_details && paylinkData.vendor_details.company}
                                  </span>
                                </Col>
                              </Row>

                              <Row style={{ marginBottom: "20%" }}>
                                <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                                  <Row className='text-start mx-5' >
                                    <p className='' style={{
                                      color: "#BCBCBC",
                                      fontSize: "16px",
                                      fontWeight: "bold",
                                      fontFamily: "Inter",
                                      lineHeight: "17px",
                                      wordWrap: "break-word",
                                      overflowWrap: "break-word",
                                      wordBreak: "break-word",
                                    }}>
                                      {payment_checkout && payment_checkout.paylinkDetails.title}
                                    </p>
                                  </Row>
                                  <Row className='text-start mx-5' >
                                    <p className='w-75' style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                      {payment_checkout && payment_checkout.paylinkDetails.description}
                                    </p>
                                  </Row>
                                  <hr
                                    className="my-2"
                                    style={{ marginLeft: '3.6rem', width: "82%" }}

                                  />
                                  <Row className="j-c-center mx-5">
                                    <Col
                                      xs={12}
                                    >
                                      <span
                                        className="resulted-amount"
                                        style={{
                                          textAlign: "left",
                                          color: "#DAAD66",
                                          fontFamily: "Inter",
                                          fontSize: "30px",
                                          fontStyle: "normal",
                                          fontWeight: "900",
                                          lineHeight: "normal",
                                        }}>
                                        {payment_checkout && payment_checkout.checkoutOrder && payment_checkout.checkoutOrder.resultedAmount} <small>TND</small>
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row className='mx-5 my-2 px-2 product-list' style={{ maxHeight: "420px", overflowY: "auto" }}>
                                    <Col xs={12}>
                                      {
                                        payment_checkout.checkoutOrder.products.map((check_product: any, index: any) => {
                                          const product = payment_checkout.availableProducts.find((prod: any) => prod.id == check_product.product_id);
                                          const firstOcc = payment_checkout.checkoutOrder.products.findIndex((prod: any) => prod.product_id === check_product.product_id);
                                          let hasMultipleOptionValues = false;

                                          const productQuantityInCheckout = payment_checkout.checkoutOrder.products
                                            .filter((prod: any) => prod.product_id === product.id)
                                            .reduce((total: number, prod: any) => total + prod.quantity, 0);

                                          const availableQty = paylinkData?.qty_available?.find((item: any) => item.product_id === product.id)?.qty || 0;

                                          const maxLimit = (availableQty - productQuantityInCheckout) + payment_checkout.checkoutOrder.products[index].quantity;

                                          if (product.options.length > 0) hasMultipleOptionValues = product.options.some((option: any) => option.option_values.length > 1);

                                          return (
                                            <>
                                              <hr className=""
                                                style={{
                                                  marginBottom: "10px",
                                                  marginTop: "10px"
                                                }} />
                                              <Row
                                                className={`product-item ${check_product.quantity === 0 && 'product-item-disabled'}`}
                                                style={{ borderRadius: "20px", paddingTop: "10px", paddingBottom: "10px" }}

                                              >
                                                <Col xs={12}>
                                                  <Row className='d-flex align-items-start w-100'>

                                                    <Col sm={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                      <div
                                                        className='product-item-img-container'
                                                        style={{
                                                          border: "1px solid #ddd",
                                                          borderRadius: "15px",
                                                          padding: "",
                                                        }}
                                                      >
                                                        <img
                                                          className="product-item-img"
                                                          src={product.picture ? baseImgUrl + product.picture : emptyProductImg}
                                                          alt="The product image"
                                                          width="40px"
                                                          height="40px"
                                                          style={{ borderRadius: '10px' }}
                                                        />
                                                      </div>

                                                    </Col>

                                                    <Col sm={7} className='d-flex flex-column justify-content-start text-start h-100'>
                                                      <div className="product-item-details">
                                                        <div >
                                                          <span
                                                            className="product-item-label"
                                                          >{product.label}</span>
                                                        </div>
                                                        <div >
                                                          <span
                                                            className="product-item-description"
                                                          >{product.description}</span>
                                                        </div>
                                                      </div>
                                                    </Col>

                                                    <Col sm={3} className="d-flex justify-content-end">
                                                      <span
                                                        className="product-item-price"
                                                      >
                                                        {check_product.price} <small>TND</small>
                                                      </span>
                                                    </Col>
                                                  </Row>
                                                  <Row className='d-flex align-items-center w-100 mt-3'>
                                                    <Col sm={2} className="d-flex flex-column align-items-start">
                                                      {product.options.length > 0 && hasMultipleOptionValues && firstOcc === index &&

                                                        <FaRegClone
                                                          className=""
                                                          style={{ color: '#63a4ff', fontSize: "22px", cursor: check_product.quantity === 0 ? "not-allowed" : "pointer" }}
                                                          onClick={check_product.quantity === 0 ? undefined : () => handleAddAnotherProductToOrder(product.id, index)}
                                                        />
                                                      }

                                                      {firstOcc !== index &&
                                                        <BsTrash
                                                          className=""
                                                          style={{ color: '#D83A52', fontSize: "22px", cursor: "pointer" }}
                                                          onClick={() => handleDeleteProductFromOrder(product.id, index)}
                                                        />
                                                      }
                                                    </Col>
                                                    <Col sm={7} className="">
                                                      <Row className="d-flex justify-content-start align-items-end h-100">
                                                        {
                                                          product.options.map((option: any, option_index: any) => <>
                                                            <Col sm={6} className="product-option d-flex justify-content-center">
                                                              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                                                <InputLabel id={`select-label-${index}-${option.id}`}>{option.label}</InputLabel>
                                                                <FloatingLabelSelect
                                                                  labelId={`select-label-${index}-${option.id}`}
                                                                  id={`select-${index}-${option.id}`}
                                                                  label={option.label}
                                                                  value={check_product.options[option_index].option_value_id}
                                                                  onChange={(e) => handleCheckoutProductOptionSelect(index, product.id, option.id, e.target.value)}
                                                                  style={{
                                                                    fontSize: "12px",
                                                                    fontWeight: "300",
                                                                    fontFamily: 'Inter',
                                                                    lineHeight: "14px",
                                                                    cursor: "pointer",
                                                                    border: "0.5px solid #CECECE"
                                                                  }}
                                                                /* sx={{
                                                                  fontSize: "12px",
                                                                  fontWeight: "300",
                                                                  fontFamily: 'Inter',
                                                                  lineHeight: "14px",
                                                                  cursor: "pointer",
                                                                  height: "30px",
                                                                  '.MuiOutlinedInput-root': {
                                                                    padding: '5px 14px',
                                                                  },
                                                                  '.MuiSelect-select': {
                                                                    paddingTop: '6px',
                                                                    paddingBottom: '6px',
                                                                  },
                                                                }} */
                                                                >
                                                                  {option.option_values.map((ov: any, index: any) => (
                                                                    <MenuItem key={ov.id} value={ov.id}>{ov.label}</MenuItem>
                                                                  ))}
                                                                </FloatingLabelSelect>
                                                              </FormControl>
                                                              {/* <Input
                                                                type="select"
                                                                name="optional_field_type"
                                                                id="optional_field_type"
                                                                style={{
                                                                  width: "120px",
                                                                  height: "25px",
                                                                  fontSize: "12px",
                                                                  fontWeight: "300",
                                                                  fontFamily: 'Inter',
                                                                  lineHeight: "14px",
                                                                  cursor: "pointer",
                                                                  border: "0.5px solid #CECECE"
                                                                }}
                                                                onChange={(e) => handleCheckoutProductOptionSelect(index, product.id, option.id, e.target.value)}
                                                              >
                                                                {option.option_values.map((ov: any, index: any) => (
                                                                  <option value={ov.id}>{ov.label}</option>
                                                                ))}
                                                              </Input> */}
                                                            </Col>
                                                          </>
                                                          )}

                                                      </Row>
                                                    </Col>
                                                    <Col sm={3} className="d-flex justify-content-end">
                                                      <QtyInput
                                                        quantity={payment_checkout.checkoutOrder.products[index].quantity || 0}
                                                        //maxLimit={product.qty === 0 ? undefined : product.qty}
                                                        //maxLimit={paylinkData && paylinkData.qty_available && paylinkData.qty_available.find((item: any) => item.product_id == product.id).qty}
                                                        maxLimit={maxLimit}

                                                        //minLimit="0"
                                                        onQuantityChange={(qty) => handleProductQuantityChange(index, qty)}

                                                      />
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                              {index == payment_checkout.checkoutOrder.products.length - 1 && (
                                                <hr
                                                  style={{
                                                    marginBottom: "10px",
                                                    marginTop: "10px",
                                                  }}
                                                  className=""
                                                />
                                              )}
                                            </>
                                          )
                                        })
                                      }
                                    </Col>
                                  </Row>
                                  {
                                    payment_checkout.availableDeliveryOption &&
                                    <>
                                      <Row className="j-c-center mx-5 my-3">
                                        <span style={{
                                          fontFamily: "Inter",
                                          fontSize: "24px",
                                          fontWeight: "600",
                                          lineHeight: "30px",
                                          textAlign: "left",
                                          color: "#515151"
                                        }}>Livraison</span>
                                      </Row>
                                      {
                                        !payment_checkout.checkoutOrder.delivery &&

                                        <Alert color="warning" className="mx-5 my-3">
                                          <FaInfoCircle /> This delivery is mandatory
                                        </Alert>
                                      }

                                      <div className="delivery-options">
                                        {payment_checkout.availableDeliveryOption.options[0].option_values.map((ov: any, index: any) =>
                                          <Row
                                            className={`delivery-option ${payment_checkout.checkoutOrder.delivery == ov.id && 'delivery-option--selected'} my-2 mx-5 p-2 d-flex justify-content-between`}
                                            onClick={(event) => {
                                              if (!(event.target as HTMLElement).closest('input[type="checkbox"]')) {
                                                payment_checkout.checkoutOrder.delivery == ov.id
                                                  ? dispatch(chooseDeliveryOption(null))
                                                  : dispatch(chooseDeliveryOption(parseInt(ov.id)));
                                              }
                                            }}
                                          >
                                            <Col sm={7} className='d-flex flex-column justify-content-start text-start h-100'>
                                              <div className="text">
                                                <div className="label" style={{ color: "#515151", fontSize: "20px", fontWeight: "600", fontFamily: "Inter", lineHeight: "24px" }}>
                                                  {ov.label}
                                                </div>
                                                <div className="mt-3" style={{ color: "#666666", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                                  {ov.description}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col sm={3}>
                                              <div className="text" style={{ textAlign: "right" }}>
                                                <div className="label" style={{ color: "#515151", fontSize: "14px", fontWeight: "600", fontFamily: "Inter", lineHeight: "18px" }}>
                                                  {ov.price} TND
                                                </div>
                                                <div
                                                  className="description mt-3"
                                                  style={{ color: "#CECCCC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px", textAlign: "right" }}>
                                                  <FormGroup
                                                    check
                                                    inline

                                                    style={{ marginRight: "0px" }}
                                                  >
                                                    <Input
                                                      style={{ cursor: "pointer", width: "20px", height: "20px" }}
                                                      type="radio"
                                                      onChange={handleDeliveryChoosed}
                                                      value={ov.id}
                                                      checked={payment_checkout.checkoutOrder.delivery == ov.id}
                                                    />
                                                  </FormGroup>
                                                </div>
                                              </div>
                                            </Col>

                                          </Row>
                                        )}
                                      </div>
                                    </>
                                  }

                                  <hr
                                    className="my-3 mx-5"
                                  />
                                  <Row className="mx-5 d-flex justify-content-between">
                                    <Col sm={3}>
                                      <span style={{ fontFamily: "Inter", fontWeight: "600", fontSize: "20px", color: "#515151" }}>Total</span>
                                    </Col>
                                    <Col sm={2}>
                                      <span
                                        className="label"
                                        style={{ color: "#515151", fontSize: "14px", fontWeight: "600", fontFamily: "Inter", lineHeight: "18px" }}
                                      >
                                        {payment_checkout && payment_checkout.checkoutOrder && payment_checkout.checkoutOrder.resultedAmount} TND
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col sm={12} md={12} lg={6} xl={6} xxl={6} style={{ borderLeft: "4px solid rgb(246, 248, 250)", padding: "0" }}>
                                  <Row className='my-3 mx-5' style={{ overflowY: "auto", maxHeight: "800px", overflowX: "hidden" }}>
                                    <PayerContactForm onLoadPayment={handleLoadPayment} />
                                  </Row>
                                </Col>
                              </Row>
                            </> : payment_checkout.paylinkType == "event" ?
                              <>
                                <div style={{ position: 'relative', width: '100%', height: '200px' }}>
                                  <img
                                    src={base_img_url + payment_checkout.availableProducts[0].picture}
                                    alt="Placeholder"
                                    style={{ width: "100%", height: "200px", objectFit: 'cover' }}
                                  />
                                  <div className="d-flex justify-content-end" style={{ position: 'absolute', top: 0, right: 0, marginTop: '10px', marginRight: "20px" }}>
                                    <span className="payment-step-text payment-step-text--white">Etape {payment_checkout.actualStep}/3</span>
                                  </div>
                                </div>
                                <Row className="my-5 d-flex align-items-center justify-content-center text-center">
                                  <span
                                    className="event_title"
                                    style={{ fontSize: "40px", fontWeight: "900", fontFamily: "Inter", color: "#515151" }}
                                  >{payment_checkout.availableProducts[0].label}</span>
                                </Row>

                                <Row className="mt-5 mb-2 mx-5">
                                  <Col xs="auto">
                                    <div style={{ border: "2px solid #ddd", borderRadius: "20px", padding: "10px" }}>
                                      <img
                                        src={paylinkData && paylinkData.vendor_details && paylinkData.vendor_details.logo !== "" ? paylinkData && base_img_url + "/media/" + paylinkData.vendor_details.logo : emptyMerchantImg}
                                        width="40px"
                                        height="40px"
                                        style={{ borderRadius: "10px" }}
                                      />
                                    </div>
                                  </Col>
                                  <Col className="d-flex align-items-end py-2">
                                    <span className="merchant_name" style={{ fontSize: "24px", fontWeight: "600", fontFamily: "Inter", color: "#515151" }}>
                                      {paylinkData.vendor_details && paylinkData.vendor_details.company}
                                    </span>
                                  </Col>
                                </Row>
                                <Row style={{ marginBottom: "20%" }}>
                                  <Col>
                                    <Row className='text-start mx-5' >
                                      <p className='w-75' style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                        {payment_checkout.availableProducts[0].description}
                                      </p>
                                    </Row>
                                    <hr
                                      className="my-2 mx-5"
                                      style={{ width: "80%" }}
                                    />
                                    <Row className="j-c-center mx-5 my-3">
                                      <Col
                                        xs={12}
                                      >
                                        <span
                                          className="resulted-amount"
                                          style={{
                                            textAlign: "left",
                                            color: "#DAAD66",
                                            fontFamily: "Inter",
                                            fontSize: "30px",
                                            fontStyle: "normal",
                                            fontWeight: "900",
                                            lineHeight: "normal",
                                          }}>
                                          {payment_checkout && payment_checkout.checkoutOrder && payment_checkout.checkoutOrder.resultedAmount} <small>TND</small>
                                        </span>
                                      </Col>
                                    </Row>
                                    <Row className="j-c-center mx-5 my-3">
                                      <span style={{
                                        fontFamily: "Inter",
                                        fontSize: "24px",
                                        fontWeight: "600",
                                        lineHeight: "30px",
                                        textAlign: "left",
                                        color: "#034DA3"
                                      }}>Tickets</span>
                                    </Row>
                                    {
                                      payment_checkout.availableProducts[0].options[0].option_values.map((ov: any, index: any) => {
                                        const products = payment_checkout.checkoutOrder.products;
                                        let checkout_ov;

                                        return (
                                          <Row className='mx-5 my-2' style={{ maxHeight: "500px", overflowY: "auto" }}>
                                            <Col xs={12}>
                                              <EventItem itemObj={ov} itemFor="event" onQuantityChange={handleOptionQuantityChange} qtyAvailable={paylinkData && paylinkData.qty_available && paylinkData.qty_available.find((item: any) => item.event_id == ov.id).qty} />
                                              <hr
                                                style={{
                                                  marginBottom: "10px",
                                                  marginTop: "10px",
                                                }}
                                                className="mx-2"
                                              />
                                            </Col>
                                          </Row>
                                        )
                                      }
                                      )}


                                    {
                                      payment_checkout.availableProducts[0].options[1] &&
                                      <Row className="j-c-center mx-5 my-3">
                                        <span style={{
                                          fontFamily: "Inter",
                                          fontSize: "24px",
                                          fontWeight: "600",
                                          lineHeight: "30px",
                                          textAlign: "left",
                                          color: "#034DA3"
                                        }}>Extra</span>
                                      </Row>
                                    }

                                    {
                                      payment_checkout.availableProducts[0].options[1] && payment_checkout.availableProducts[0].options[1].option_values.map((ov: any, index: any) =>
                                        <Row className='mx-5 my-2' style={{ maxHeight: "500px", overflowY: "auto" }}>
                                          <Col xs={12}>
                                            <EventItem itemObj={ov} itemFor="extra" onQuantityChange={handleExtrasQuantityChange} />
                                            <hr
                                              style={{
                                                marginBottom: "10px",
                                                marginTop: "10px",
                                              }}
                                              className="mx-2"
                                            />
                                          </Col>
                                        </Row>
                                      )
                                    }
                                    <Row className="mx-5 d-flex justify-content-between">
                                      <Col sm={3}>
                                        <span style={{ fontFamily: "Inter", fontWeight: "600", fontSize: "20px", color: "#515151" }}>Total</span>
                                      </Col>
                                      <Col sm={2}>
                                        <span
                                          className="label"
                                          style={{ color: "#515151", fontSize: "14px", fontWeight: "600", fontFamily: "Inter", lineHeight: "18px" }}
                                        >
                                          {payment_checkout && payment_checkout.checkoutOrder && payment_checkout.checkoutOrder.resultedAmount} TND
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col style={{ borderLeft: "4px solid rgb(246, 248, 250)", padding: "0" }}>
                                    <Row className='my-3 mx-5'>
                                      <PayerContactForm onLoadPayment={handleLoadPayment} />
                                    </Row>
                                  </Col>
                                </Row>
                              </> : payment_checkout.paylinkType == "donation" ?
                                <>
                                  <div className="payment-step d-flex justify-content-end mx-4 my-2">
                                    <span className="payment-step-text payment-step-text--gray">Etape {payment_checkout.actualStep}/3</span>
                                  </div>
                                  <Row className="m-5">
                                    <Col xs="auto">
                                      <div style={{ border: "2px solid #ddd", borderRadius: "20px", padding: "10px" }}>
                                        <img
                                          src={paylinkData.vendor_details.logo !== "" ? paylinkData && base_img_url + "/media/" + paylinkData.vendor_details.logo : emptyMerchantImg}
                                          width="40px"
                                          height="40px"
                                          style={{ borderRadius: "10px" }}
                                        />
                                      </div>
                                    </Col>
                                    <Col className="d-flex align-items-end py-2">
                                      <span className="merchant_name" style={{ fontSize: "24px", fontWeight: "600", fontFamily: "Inter", color: "#515151" }}>
                                        {paylinkData.vendor_details && paylinkData.vendor_details.company}
                                      </span>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Row className="row mx-5" style={{ width: '80%' }}>
                                        <img
                                          src={payment_checkout && payment_checkout.photoDonation}
                                          alt="Placeholder"
                                          style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '50px' }}
                                        />
                                      </Row>

                                      <Row className='justify-content-center mx-5 my-3'>
                                        <p className='w-75 text-start' style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                          {payment_checkout && payment_checkout.paylinkDetails.description}
                                        </p>
                                      </Row>
                                      <Row className='justify-content-center mx-5 my-3'>
                                        <hr
                                          className="my-3 mx-5 w-75"
                                        />
                                      </Row>
                                      <Row
                                        className="d-flex justify-content-center"
                                      >
                                        <Col sm={4}>
                                          <span style={{ fontFamily: "Inter", fontSize: "20px", fontWeight: "600", lineHeight: "24px" }}>Donation amont</span>
                                        </Col>
                                      </Row>
                                      <Row
                                        className="d-flex justify-content-center"
                                        style={{
                                          paddingLeft: "3px",
                                          marginTop: "24px",
                                          marginBottom: "12px%",
                                        }}
                                      >
                                        <Col sm={5}>
                                          <FormGroup>
                                            <InputGroup>
                                              <Input
                                                name="checkout_amount_donation"
                                                placeholder="0.000"
                                                onChange={(event: any) => dispatch(updateResultedAmountDonation(event.target.value))}
                                                value={payment_checkout.checkoutOrder.resultedAmount}
                                              />
                                              <InputGroupText style={{ backgroundColor: "white" }}>
                                                TND
                                              </InputGroupText>
                                            </InputGroup>
                                          </FormGroup>
                                        </Col>

                                      </Row>
                                    </Col>
                                    <Col style={{ borderLeft: "4px solid rgb(246, 248, 250)", padding: "0" }}>
                                      <Row className='my-3 mx-5'>
                                        <PayerContactForm onLoadPayment={handleLoadPayment} />
                                      </Row>
                                    </Col>
                                  </Row>
                                </> :
                                payment_checkout.paylinkType == "subscription" ? <>
                                  <div className="payment-step d-flex justify-content-end mx-4 my-2">
                                    <span className="payment-step-text payment-step-text--gray">Etape {payment_checkout.actualStep}/3</span>
                                  </div>
                                  <Row className="m-5">
                                    <Col xs="auto">
                                      <div className="merchant-logo-container" style={{ border: "2px solid #ddd", borderRadius: "20px", padding: "10px" }}>
                                        <img
                                          className="merchant-logo-img"
                                          src={paylinkData && paylinkData.vendor_details && paylinkData.vendor_details.logo !== "" ? paylinkData && base_img_url + "/media/" + paylinkData.vendor_details.logo : emptyMerchantImg}
                                          width="40px"
                                          height="40px"
                                          style={{ borderRadius: "10px" }}
                                        />
                                      </div>
                                    </Col>
                                    <Col className="d-flex align-items-end py-2">
                                      <span className="merchant-name" style={{ fontSize: "24px", fontWeight: "600", fontFamily: "Inter", color: "#515151" }}>
                                        {paylinkData.vendor_details && paylinkData.vendor_details.company}
                                      </span>
                                    </Col>
                                  </Row>

                                  <Row style={{ marginBottom: "20%" }}>
                                    <Col sm={12} md={12} lg={6} xl={6} xxl={6}>
                                      <Row className='text-start mx-5' >
                                        <p className='' style={{
                                          color: "#BCBCBC",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          fontFamily: "Inter",
                                          lineHeight: "17px",
                                          wordWrap: "break-word",
                                          overflowWrap: "break-word",
                                          wordBreak: "break-word",
                                        }}>
                                          {payment_checkout && payment_checkout.paylinkDetails.title}
                                        </p>
                                      </Row>
                                      <Row className='text-start mx-5' >
                                        <p className='w-75' style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                          {payment_checkout && payment_checkout.paylinkDetails.description}
                                        </p>
                                      </Row>
                                      <hr
                                        className="my-2"
                                        style={{ marginLeft: '3.6rem', width: "82%" }}

                                      />

                                      <Row className="j-c-center mx-5">
                                        <Col
                                          xs={12}
                                        >
                                          <span
                                            className="resulted-amount"
                                            style={{
                                              textAlign: "left",
                                              color: "#DAAD66",
                                              fontFamily: "Inter",
                                              fontSize: "30px",
                                              fontStyle: "normal",
                                              fontWeight: "900",
                                              lineHeight: "normal",
                                            }}>
                                            {payment_checkout && payment_checkout.checkoutOrder && payment_checkout.checkoutOrder.resultedAmount} <small>TND</small>
                                          </span>
                                        </Col>
                                      </Row>
                                      <Row className='mx-5 my-2 px-2 product-list' style={{ maxHeight: "420px", overflowY: "auto" }}>
                                        <Col xs={12}>
                                          {
                                            payment_checkout.availableProducts.map((product: any, index: any) => {

                                              return (
                                                <>
                                                  <hr className=""
                                                    style={{
                                                      marginBottom: "10px",
                                                      marginTop: "10px"
                                                    }} />
                                                  <Row
                                                    key={product.id}
                                                    className={`product-item ${selectedProductId === product.id ? 'selected' : ''} ${product.quantity === 0 ? 'product-item-disabled' : ''}`}
                                                    style={{
                                                      borderRadius: "20px",
                                                      paddingTop: "1%",
                                                      paddingBottom: "1%",
                                                      cursor: "pointer",
                                                      backgroundColor: selectedProductId === product.id ? "#e9eff8" : "white",
                                                      transition: "background-color 0.3s ease",
                                                    }}
                                                    onClick={() => handleProductClick(product.id)}
                                                  >
                                                    <Col xs={12}>
                                                      <Row className='d-flex align-items-start w-100'>
                                                        <Col sm={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                          <div
                                                            className='product-item-img-container'
                                                            style={{
                                                              border: "1px solid #ddd",
                                                              borderRadius: "10px",
                                                              padding: "",
                                                            }}
                                                          >
                                                            <img
                                                              className="product-item-img"
                                                              src={product.picture ? baseImgUrl + product.picture : emptyProductImg}
                                                              alt="The product image"
                                                              width="30px"
                                                              height="30px"
                                                              style={{ borderRadius: '10px' }}
                                                            />
                                                          </div>

                                                        </Col>

                                                        <Col sm={7} className='d-flex flex-column justify-content-start text-start'>
                                                          <span
                                                            className="product-item-label d-block"
                                                            style={{ fontSize: "1rem" }}
                                                          >{product.label}</span>
                                                        </Col>

                                                        <Col sm={3} className="d-flex justify-content-end">
                                                          <span
                                                            className="product-item-price"
                                                            style={{ fontSize: "0.75rem" }}
                                                          >
                                                            {product.init_price} <small>TND</small>
                                                          </span>
                                                        </Col>
                                                      </Row>
                                                      <Row className="d-flex justify-content-between w-100 mt-1">
                                                        <Col className="text-start">
                                                          <span
                                                            className=""
                                                            style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}
                                                          >{product.description}</span>
                                                        </Col>
                                                        <Col sm={3} className="d-flex flex-column align-items-end justify-content-end">
                                                          <span
                                                            className="badge product-item-price"
                                                            style={{ backgroundColor: "#63a4ff", color: "white", textTransform: "capitalize" }}
                                                          >
                                                            {product.frequency}
                                                          </span>
                                                          {
                                                            payment_checkout.paylinkDetails.checkout_frequency_cycle > 1 &&
                                                            <span
                                                              className="badge product-item-price mt-2"
                                                              style={{ backgroundColor: "#63a4ff", color: "white" }}
                                                            >
                                                              {payment_checkout.paylinkDetails.checkout_frequency_cycle} times
                                                            </span>
                                                          }

                                                        </Col>

                                                      </Row>
                                                    </Col>
                                                  </Row>
                                                  {index == payment_checkout.availableProducts.length - 1 && (
                                                    <hr
                                                      style={{
                                                        marginBottom: "10px",
                                                        marginTop: "10px",
                                                      }}
                                                      className=""
                                                    />
                                                  )
                                                  }
                                                </>
                                              )
                                            })
                                          }
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col sm={12} md={12} lg={6} xl={6} xxl={6} style={{ borderLeft: "4px solid rgb(246, 248, 250)", padding: "0" }}>
                                      <Row className='my-3 mx-5' style={{ overflowY: "auto", maxHeight: "800px", overflowX: "hidden" }}>
                                        <PayerContactForm onLoadPayment={handleLoadPayment} />
                                      </Row>
                                    </Col>
                                  </Row>
                                </> : <></>
                          }

                        </>
                      }
                      {((payment_checkout.actualStep === 2) || (payment_checkout.actualStep === 3)) &&
                        <>
                          <div className="payment-step d-flex justify-content-end mx-4 my-2">
                            <span className="payment-step-text payment-step-text--gray">Etape {payment_checkout.actualStep}/3</span>
                          </div>
                          <Row style={{ marginBottom: "20%" }}>
                            <Col>
                              <Row className="m-5">
                                <Col xs="auto">
                                  <div className="merchant-logo-container" style={{ border: "2px solid #ddd", borderRadius: "20px", padding: "10px" }}>
                                    <img
                                      className="merchant-logo-img"
                                      src={paylinkData && paylinkData.vendor_details && paylinkData.vendor_details.logo !== "" ? paylinkData && base_img_url + "/media/" + paylinkData.vendor_details.logo : emptyMerchantImg}
                                      width="40px"
                                      height="40px"
                                      style={{ borderRadius: "10px" }}
                                    />
                                  </div>
                                </Col>
                                <Col className="d-flex align-items-end py-2">
                                  <span className="merchant-name" style={{ fontSize: "24px", fontWeight: "600", fontFamily: "Inter", color: "#515151" }}>
                                    {paylinkData.vendor_details && paylinkData.vendor_details.company}
                                  </span>
                                </Col>
                              </Row>
                              <Row className='text-start mx-5' >
                                <p className='w-75' style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                  {payment_checkout.paylinkType == "sale" ? payment_checkout.paylinkDetails.description : payment_checkout.availableProducts[0].description}
                                </p>
                              </Row>
                              <hr
                                className="my-3 mx-5"
                                style={{ width: "80%" }}
                              />

                              <Row className="mx-5 my-3">
                                <span style={{
                                  fontFamily: "Inter",
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  lineHeight: "24px",
                                  textAlign: "left",
                                  color: "#213A7D"
                                }}>Contact information </span>
                              </Row>

                              <Row className='text-start mx-5' >
                                <Col sm={12}>
                                  <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> First Name : {payment_checkout.payerInfos.firstname} </span>
                                </Col>
                                <Col sm={12}>
                                  <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> Last Name : {payment_checkout.payerInfos.lastname}  </span>
                                </Col>
                                <Col sm={12}>
                                  <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> E-mail : {payment_checkout.payerInfos.email} </span>
                                </Col>
                                <Col sm={12}>
                                  <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> Phone : {payment_checkout.payerInfos.phone} </span>
                                </Col>
                              </Row>
                              <hr
                                className="my-3 mx-5"
                                style={{ width: "80%" }}
                              />
                              {
                                payment_checkout.paylinkType == "sale" ?
                                  <>
                                    <Row className="mx-5 my-3">
                                      <span style={{
                                        fontFamily: "Inter",
                                        fontSize: "20px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                        color: "#213A7D"
                                      }}>Check out </span>
                                    </Row>
                                    <div className="checkout_table mx-5">
                                      <Row
                                        className="table-header mt-2 mb-3 mx-2"
                                        style={{
                                          backgroundColor: "#709dd3",
                                          color: "#ffffff",
                                          fontFamily: "Inter",
                                          fontSize: "15px",
                                          borderRadius: "5px",
                                          fontWeight: "bold",
                                          padding: "10px 0"
                                        }}>
                                        <Col sm={3} className="text-center">Article</Col>
                                        <Col sm={6} className="text-center">Caractéristique</Col>
                                        <Col sm={3} className="text-center">Quantité</Col>
                                      </Row>
                                      <div
                                        className="table-rows mx-2"
                                        style={{
                                          maxHeight: "100px",
                                          overflowY: "auto",
                                          overflowX: "hidden"

                                        }}
                                      >
                                        {
                                          payment_checkout.paylinkType == "sale" && payment_checkout.checkoutOrder.products.map((check_product: any, index: any) => {
                                            const product = payment_checkout.availableProducts.find((prod: any) => prod.id === check_product.product_id);
                                            return (
                                              <Row className="table-rows my-2"
                                                style={{
                                                  backgroundColor: "#fff",
                                                  color: "#3F3F3F",
                                                  fontFamily: "Inter",
                                                  fontSize: "14px",
                                                  fontWeight: 400,
                                                  borderRadius: "5px",
                                                  padding: "10px 0"
                                                }}
                                              >
                                                <Col sm={3} className="text-center">{product.label}</Col>
                                                <Col sm={6} className="text-center">
                                                  {
                                                    check_product.options && check_product.options.length > 0 ? (
                                                      <>
                                                        {
                                                          check_product.options.map((check_option: any, optIndex: any) => {
                                                            const option = product.options.find((opt: any) => opt.id === check_option.option_id);
                                                            const option_value = option.option_values.find((val: any) => val.id === check_option.option_value_id);

                                                            return option_value ? option_value.label : "";
                                                          }).filter(Boolean).join("_")
                                                        }
                                                      </>
                                                    ) : (
                                                      "N/A"
                                                    )
                                                  }

                                                </Col>
                                                <Col sm={3} className="text-center">{check_product.quantity}</Col>
                                              </Row>
                                            );
                                          })
                                        }
                                      </div>

                                    </div>
                                  </>
                                  :
                                  payment_checkout.paylinkType == "event" ?
                                    <>
                                      <Row className="mx-5 my-3">
                                        <span style={{
                                          fontFamily: "Inter",
                                          fontSize: "20px",
                                          fontWeight: "400",
                                          lineHeight: "24px",
                                          textAlign: "left",
                                          color: "#213A7D"
                                        }}>Tickets </span>
                                      </Row>
                                      <div className="checkout_table mx-5">
                                        <Row
                                          className="table-header mt-2 mb-3 mx-2"
                                          style={{
                                            backgroundColor: "#709dd3",
                                            color: "#ffffff",
                                            fontFamily: "Inter",
                                            fontSize: "15px",
                                            borderRadius: "5px",
                                            fontWeight: "bold",
                                            padding: "10px 0"
                                          }}>
                                          <Col sm={6} className="text-center">Ticket</Col>
                                          <Col sm={6} className="text-center">Quantité</Col>
                                        </Row>
                                        <div
                                          className="table-rows mx-2"
                                          style={{
                                            maxHeight: "100px",
                                            overflowY: "auto",
                                            overflowX: "hidden"

                                          }}
                                        >
                                          {
                                            payment_checkout.paylinkType == "event" && payment_checkout.checkoutOrder.products.map((check_product: any, index: any) => {
                                              const event = payment_checkout.availableProducts[0].options[0].option_values.find((val: any) => val.id == check_product.event_id);

                                              return (
                                                <Row className="table-row my-2"
                                                  style={{
                                                    backgroundColor: "#fff",
                                                    color: "#3F3F3F",
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    borderRadius: "5px",
                                                    padding: "10px 0"
                                                  }}
                                                >
                                                  <Col sm={6} className="text-center">{event.label}</Col>
                                                  <Col sm={6} className="text-center">{check_product.quantity}</Col>
                                                </Row>
                                              );
                                            })
                                          }
                                        </div>

                                      </div>

                                      <Row className="mx-5 my-3">
                                        <span style={{
                                          fontFamily: "Inter",
                                          fontSize: "20px",
                                          fontWeight: "400",
                                          lineHeight: "24px",
                                          textAlign: "left",
                                          color: "#213A7D"
                                        }}>Extra </span>
                                      </Row>
                                      <div className="extras_table mx-5">
                                        <Row
                                          className="table-header mt-2 mb-3 mx-2"
                                          style={{
                                            backgroundColor: "#EAF4FF",
                                            color: "#3F3F3F",
                                            fontFamily: "Inter",
                                            fontSize: "15px",
                                            borderRadius: "5px",
                                            fontWeight: "bold",
                                            padding: "10px 0"
                                          }}>
                                          <Col sm={6} className="text-center">Article</Col>
                                          <Col sm={6} className="text-center">Quantity</Col>
                                        </Row>
                                        <div
                                          className="table-rows mx-2"
                                          style={{
                                            maxHeight: "100px",
                                            overflowY: "auto",
                                            overflowX: "hidden"

                                          }}
                                        >

                                          {
                                            payment_checkout.paylinkType == "event" && payment_checkout.checkoutOrder.extras.map((check_product: any, index: any) => {
                                              const extra = payment_checkout.availableProducts[0].options[1].option_values.find((val: any) => val.id == check_product.extra_id);

                                              return (
                                                <Row className="table-row my-2"
                                                  style={{
                                                    backgroundColor: "#fff",
                                                    color: "#3F3F3F",
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: 400,
                                                    borderRadius: "5px",
                                                    padding: "10px 0"
                                                  }}
                                                >
                                                  <Col sm={6} className="text-center">{extra.label}</Col>
                                                  <Col sm={6} className="text-center">{check_product.quantity}</Col>
                                                </Row>
                                              );
                                            })
                                          }
                                        </div>

                                      </div>

                                    </> :
                                    payment_checkout.paylinkType == "subscription" ?
                                      <>
                                        <Row className="mx-5 my-3">
                                          <span style={{
                                            fontFamily: "Inter",
                                            fontSize: "20px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                            color: "#213A7D"
                                          }}>Abonnement </span>
                                        </Row>
                                        <Row className="mx-5 my-3">
                                          <span style={{ color: "#BCBCBC", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                            {payment_checkout.availableProducts.find((prod: any) => prod.id == payment_checkout.checkoutOrder.products[0].product_id).label} ( {payment_checkout.checkoutOrder.resultedAmount} TND )
                                          </span>
                                        </Row>
                                      </> :
                                      <>
                                      </>
                              }

                              <hr
                                className="my-3 mx-5"
                                style={{ width: "80%" }}
                              />

                              <Row className='text-start mx-5 my-3'>
                                <Col sm={12}>
                                  <span style={{ color: "#3F3F3F", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}> N° de commande: {paylinkOrderNum}  </span>
                                </Col>
                                {
                                  payment_checkout.paylinkType == "sale" && payment_checkout.checkoutOrder.delivery &&
                                  <Col sm={12}>
                                    <span style={{ color: "#3F3F3F", fontSize: "14px", fontWeight: "400", fontFamily: "Inter", lineHeight: "17px" }}>
                                      Livraison:{' '}
                                      {payment_checkout.availableDeliveryOption.options[0].option_values.find((delivery: any) => delivery.id == payment_checkout.checkoutOrder.delivery).label}
                                      {' ('}
                                      {payment_checkout.availableDeliveryOption.options[0].option_values
                                        .find((delivery: any) => delivery.id == payment_checkout.checkoutOrder.delivery).price}
                                      {' TND)'}
                                    </span>

                                  </Col>
                                }
                              </Row>

                            </Col>
                            <Col style={{ borderLeft: "4px solid rgb(246, 248, 250)", padding: "0" }}>
                              <Row className='text-start mt-5 mx-5 mb-2'>
                                <span
                                  className="text-center"
                                  style={{
                                    color: "#515151",
                                    fontFamily: "Inter, sans-serif",
                                    fontSize: "20px",
                                    //fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "23px",
                                  }}>
                                  Montan a payer
                                </span>
                              </Row>
                              <Row className='text-start mx-5 mb-4'>
                                <span
                                  className="text-center"
                                  style={{
                                    color: "#DAAD66",
                                    fontFamily: "Inter, sans-serif",
                                    fontSize: "34px",
                                    //fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "40px",
                                  }}>
                                  {payment_checkout && payment_checkout.checkoutOrder && payment_checkout.checkoutOrder.resultedAmount} <small>TND</small>
                                </span>
                              </Row>
                              <Provider store={store}>
                                <iframe
                                  //src="https://app.paymee.tn/gateway/156ab1f32fd931a7d9e47f2a1f898e55/"
                                  src={paymentDetails.formUrl.startsWith('http://') ? paymentDetails.formUrl.replace('http://', 'https://') : paymentDetails.formUrl}
                                  width="90%"
                                  height="700px"

                                  allowTransparency={true}
                                  frameBorder={0}
                                  style={{ backgroundColor: "#FFFFFF", overflow: "hidden", marginLeft: "5%" }}
                                ></iframe>
                              </Provider>
                            </Col>
                          </Row>
                        </>
                      }

                      <div style={{ position: "absolute", bottom: "0", marginBottom: "2%", left: "0", right: "0" }}>
                        <div className="text-center mt-5">
                          <span><FaLock color="#00FF00" /> <span style={{ color: "#BCBCBC", fontWeight: '400', fontFamily: "Inter" }}>Le paiement par carte bancaire est géré par la Société Monétique de Tunisie</span></span>
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                          <span style={{ fontSize: "12px" }}>Crée par</span>
                          <img style={{ marginLeft: "15px" }} src={logo} height={25} width={85}></img>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </>
              ) : (
                <Row className="justify-content-center" style={{ width: "100%", marginTop: "15%" }}>
                  <DotLoader
                    color="#709DD3"
                    loading
                    size={66}
                    speedMultiplier={1}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </Row>
              )
            }

          </div>
        </div >
      </div >
    </>
  );
};

export default PaylinkCheckout;
