import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Badge, Button, Card, CardBody, Col, Container, FormFeedback, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Popover, PopoverBody, Row, Spinner, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap';
import { FaArrowLeft, FaArrowRight, FaCaretDown, FaCheck, FaDesktop, FaDonate, FaHandHoldingHeart, FaInfoCircle, FaLock, FaMobile, FaPlus, FaRegFolderOpen, FaShoppingCart, FaSyncAlt, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { FiFilter, FiGift, FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { updateActiveTab, addOptionalField, addProduct, fetchProductList, fetchDeliveryOptions, removeProduct, updateChekoutType, setAfterPayment, updatePaylinkDetails, removeAvailableProduct, setSelectedDeliveryOption, addAvailableProduct, resetSelectedProducts, setCheckoutFormErrors, setEventSingleMoreChoice, addExtraEventField, updateExtraEventField, toggleShowExtraEventQuantity, setExtraFieldError, clearExtraFieldError, updateResultedAmount, updateProductQuantity, updateOptionQuantity, savePhotoDonation, savePhotoEvent, setPhotoEdited, removeOptionalField, removeExtraEventField, selectEventProduct } from '../../redux/slices/checkoutBuilderSlice';
import OptionalField from './OptionalField';
import { createPaylinkCheckout } from '../../services/api/panormix';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { TiArrowUnsorted, TiWarningOutline } from "react-icons/ti";
import "./PaylinkCheckoutGenerate.css";
import { GrMore, GrPowerReset, GrStatusInfo } from 'react-icons/gr';
import { GiTicket } from "react-icons/gi";
import { FaSackDollar } from "react-icons/fa6";
import LinkInputWithPopover from '../../components/form/input/LinkInputWithPopover';
import DropZoneHolder from "../../components/form/dropzone/DropZoneHolder";
import { TfiTrash } from 'react-icons/tfi';
import IconToExplain from '../../components/ui/IconToExplain';
import emptyTableImage from "../../assets/images/empty-table.svg";
import MultiRangeSlider from '../../components/MultiRangeSlider/MultiRangeSlider';
import { FcEditImage } from 'react-icons/fc';
import toast from 'react-hot-toast';
import { BsBox2 } from 'react-icons/bs';
import { CheckoutDropDownOption, CheckoutDropDownOptions, CheckoutDropDownOptions as DropDownOptions } from '../../utils/data';
import { descriptors } from 'chart.js/dist/core/core.defaults';
import { AddProduct } from '../../components/product/addProduct';
import emptyProductImg from '../../assets/images/empty_product_img.png';
import PInput from '../../components/form/input/Input';
import { formatDate } from '../../utils/helper';

interface CheckoutGeneratorProps {
    selectedOption: CheckoutDropDownOption | null
}

const CheckoutGenerator: React.FC<CheckoutGeneratorProps> = ({ selectedOption }) => {

    const [selectTypeModal, setSelectTypeModal] = useState(true);
    const [CheckoutLinkType, setCheckoutLinkType] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [openAccordion, setOpenAccordion] = useState('1');
    const [searchTerm, setSearchTerm] = useState("");

    //const dispatch = useDispatch();
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const optionalFields = useSelector((state: any) => state.checkoutBuilder.optionalFields);
    const availableProducts = useSelector((state: any) => state.checkoutBuilder.availableProducts);
    const selectedProducts = useSelector((state: any) => state.checkoutBuilder.selectedProducts);
    const availableDeliveryOptions = useSelector((state: any) => state.checkoutBuilder.availableDeliveryOptions);
    const selectedDeliveryOption = useSelector((state: any) => state.checkoutBuilder.selectedDeliveryOption);
    const user = useSelector((state: any) => state.user);

    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [chooseDelivery, setChooseDelivery] = useState(false);
    const [eventQuantityAllowed, setEventQuantityAllowed] = useState(false);
    const [showLimitDonation, setShowLimitDonation] = useState(false);
    const [showSetupGoalDonation, setShowSetupGoalDonation] = useState(false);

    //const selectedPaylinkType = useSelector((state: any) => state.app.checkoutType);
    const { checkoutType: selectedPaylinkType, activeTab, afterPayment, paylinkDetails, payerInfos, checkoutFormErrors, eventSingleMoreChoice, extraEventFields, showExtraFieldQuantity, extraFieldErrors, resultedAmount, checkoutOrder, photoDonation, photoEvent, editMode: isEditMode } = useSelector((state: any) => state.checkoutBuilder);

    const [showOptions, setShowOptions] = useState(false);
    const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
    const [showProductList, setShowProductList] = useState(false);

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [confirmationPage, setConfirmationPage] = useState(true);
    const [confirmationPageCustomMessage, setConfirmationPageCustomMessage] = useState(false);
    const [addProductModal, setAddProductModal] = useState(false);
    const [addProductModalType, setAddProductModalType] = useState("product");
    const [showAddCustomMsg, setShowAddCustomMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [listUpdated, setListUpdated] = useState(false)
    const [showCustomCycle, setShowCustomCycle] = useState(false)

    const nestedOptionsRef = useRef<HTMLDivElement>(null);


    //const [photoDonation, setPhotoDonation] = useState<any>("")

    const actualDateExper = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    const minDateExp = new Date().toISOString().split('T')[0];

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (nestedOptionsRef.current && !nestedOptionsRef.current.contains(event.target as Node)) {
            setShowProductList(false);
        }
    };


    const handleChildData = () => {
        setListUpdated(!listUpdated)
        dispatch(fetchProductList());
        dispatch(fetchDeliveryOptions());
    };

    const handleToggleChange = () => {
        setConfirmationPage(!confirmationPage);
    };

    const handleToggleCustomMessage = () => {
        setConfirmationPageCustomMessage(!confirmationPageCustomMessage)
    }

    const toggleChooseDelivery = () => {
        setChooseDelivery(prevState => !prevState);
        dispatch(setSelectedDeliveryOption(null))
    };

    const toggleEventQuantityAllowed = () => {
        setEventQuantityAllowed(prevState => !prevState);
    };

    const toggleShowAddCustomMsg = () => {
        setShowAddCustomMsg(prevState => !prevState);
    };

    const toggleSetLimitDonation = () => {
        setShowLimitDonation(prevState => !prevState);
        //dispatch(setSelectedDeliveryOption(null))
    };

    const toggleShowSetupGoalDonation = () => {
        setShowSetupGoalDonation(prevState => !prevState);
        //dispatch(setSelectedDeliveryOption(null))
    };

    const toggleAddProductModal = () => {
        setAddProductModal(!addProductModal);
    }

    const toggleAfterPayment = () => {
        dispatch(setAfterPayment());
    }

    const togglePopover = () => setPopoverOpen(!popoverOpen);

    const toggleShowCustomCycles = () => {
        const name = "checkout_frequency_cycle"
        if (showCustomCycle == true) dispatch(updatePaylinkDetails({ [name]: 1 }));
        setShowCustomCycle(!showCustomCycle)

    }

    const handleOptionSelect = (option: CheckoutDropDownOption) => {
        setShowOptions(false);
        dispatch(updateChekoutType(option.value));
    };

    const updateActiveTabs = (tab: string) => {
        dispatch(updateActiveTab(tab));
        toggleAfterPayment();
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles: CSSProperties = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const formatGroupLabel = (data: any) => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const styles = {
        control: (css: any) => ({
            ...css,
            width: "100%",
            height: "40px",
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '40px'
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '50px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none',
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '40px',
        }),

    };

    const closeDropdown = () => {
        setShowOptions(false);
    };

    const handleReturnPrevUrl = () => {
        window.history.back();
    };

    useEffect(() => {
        dispatch(fetchProductList());
        dispatch(fetchDeliveryOptions());

        if (isEditMode) {
            setShowLimitDonation((paylinkDetails.checkout_max_donation !== "" || paylinkDetails.checkout_min_donation !== ""))
            setShowSetupGoalDonation(paylinkDetails.checkout_donation_goal !== "")
            if (paylinkDetails.checkout_event_stock != 0) setEventQuantityAllowed(true)
        }
    }, [dispatch, selectedPaylinkType]);

    const toggleSelectTypeModal = (checkout_link_type: string) => {
        setSelectTypeModal(!selectTypeModal);
        setCheckoutLinkType(checkout_link_type);
    }

    const handleAddField = () => {

        dispatch(addOptionalField());
    };
    const handleAddProduct = () => {


        const product = availableProducts.find((product: any) => product.id == selectedProduct);

        if (product) {
            dispatch(addProduct(product));
        }
    };

    const handleSelectProduct = (selectedProduct: any) => {
        dispatch(addProduct(selectedProduct));
        dispatch(removeAvailableProduct(selectedProduct.id));
        setShowProductList(false);
        //dispatch(updateResultedAmount(resultedAmount + selectedProduct.init_price));
    };

    const handleSelectProductEvent = (selectedProduct: any) => {
        dispatch(selectEventProduct(selectedProduct));
        setShowProductList(false);
        //dispatch(updateResultedAmount(resultedAmount + selectedProduct.init_price));
    };

    const handleRemoveProduct = (productId: any) => {
        // let selectedProduct = selectedProducts.find((product: any) => product.id == productId)
        dispatch(addAvailableProduct(selectedProducts.find((product: any) => product.id == productId)))
        dispatch(removeProduct(productId));
        //dispatch(updateResultedAmount(resultedAmount - selectedProduct.init_price));
    };

    const handleRemoveAllSelectedProducts = () => {
        dispatch(resetSelectedProducts())
    }

    const toggleAccordion = (id: any) => {
        setOpenAccordion(id);
    };

    const mapProductToOptions = () => {
        const selectOptions: any = [];
        availableProducts.map((product: any) => (
            selectOptions.push({ "value": product.id, "label": product.label })
        ))
        return selectOptions;
    }

    const toggleShowProductList = () => setShowProductList(!showProductList);

    const handlePaylinkDetailsInputChange = (e: any) => {
        let { name, value } = e.target;
        const regexOnlyText = /^[a-zA-Z\s]+$/
        //const regexOnlyTextAndNum = /^[a-zA-Z0-9 ]*$/
        const regexOnlyTextAndNum = /^[a-zA-Z0-9À-ÖØ-öø-ÿ ]*$/
        const regexPrice = /^\d+([,.]\d+)?$/;

        if (name === "checkout_title") {
            dispatch(setCheckoutFormErrors({ key: 'title', value: '' }));
            if (value == "") {
                dispatch(setCheckoutFormErrors({ key: 'title', value: 'The title should not be empty' }))
            }
            else if (!regexOnlyTextAndNum.test(value)) {
                dispatch(setCheckoutFormErrors({ key: 'title', value: 'The title should not contain numbers or special chars' }))
            }

        } else if (name === "checkout_description") {
            dispatch(setCheckoutFormErrors({ key: 'description', value: '' }))
            if (value == "") {
                dispatch(setCheckoutFormErrors({ key: 'description', value: 'The description should not be empty' }))
            } else if (value.length > 500) {
                dispatch(setCheckoutFormErrors({ key: 'description', value: `The description should be at most 500 chars` }));
            }
        } else if (name === "checkout_price") {
            dispatch(setCheckoutFormErrors({ key: 'price', value: '' }))
            if (value == "") {
                dispatch(setCheckoutFormErrors({ key: 'price', value: 'The price should not be empty' }))
            } else if (!regexPrice.test(value)) {
                dispatch(setCheckoutFormErrors({ key: 'price', value: 'The price format is incorrect' }))
            } else {
                value = value.replace(/^0+(?!$)/, '');
                const [integerPart, decimalPart] = value.split(/[,.]/);
                if (integerPart.length > 4) {
                    dispatch(setCheckoutFormErrors({ key: 'price', value: 'The integer part of the price has a maximum of 4 digits' }));
                } else if (decimalPart && decimalPart.length > 3) {
                    dispatch(setCheckoutFormErrors({ key: 'price', value: 'The decimal part of the price has a maximum of 3 digits' }));
                }
                name = "checkout_price"
            }
        } else if (name === "checkout_custom_returnmsg") {
            console.log()
        } else if (name === "checkout_redirect_url") {
            console.log()
        } else if (name === "checkout_expiry_date") {
            console.log()
        }

        dispatch(updatePaylinkDetails({ [name]: value }));
    };

    const handleEventSingleMoreChoiceChange = (e: any) => {
        dispatch(setEventSingleMoreChoice(e.target.value));
    };

    const handleAddExtraField = () => {
        dispatch(addExtraEventField());
    };

    const handleExtraFieldChange = (index: any, field: any, value: any) => {
        dispatch(clearExtraFieldError({ index, field }));

        if (field === "label") {
            if (!value.trim()) {
                dispatch(setExtraFieldError({ index, field, error: `You must indicate the ${field}` }));
            }
        }

        dispatch(updateExtraEventField({ index, field, value }));
    };

    const handleRemoveExtraField = (index: any) => {
        dispatch(removeExtraEventField(index));
    };

    const handleToggleExtraFieldQuantity = () => {
        dispatch(toggleShowExtraEventQuantity());
    };

    const handleProductQuantityChange = (productId: any, qty: any) => {
        console.log("{ productId, quantity: newQuantity } : ", { productId, quantity: qty })
        dispatch(updateProductQuantity({ productId, quantity: qty }));
    };

    const handleOptionQuantityChange = (p_id: any, ov_id: any, qty: any) => {
        dispatch(updateOptionQuantity({ p_id: p_id, ov_id: ov_id, qty: qty }));
    };

    const handleGeneratePaylink = () => {
        const paylinkD = {
            "title": paylinkDetails.checkout_title,
            "description": paylinkDetails.description,
            "paylink_type": selectedPaylinkType,
            "optional_fields": optionalFields,
            "selected_products": selectedProducts,

        }

        createPaylinkCheckout(paylinkD)
            .then((res) => {
                setIsLoading(false);

                toast.success("Paylink created successfully");
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const handleSavePhotoDonation = (photoData: any) => {
        dispatch(savePhotoDonation(photoData))
    };

    const handleSavePhotoEvent = (photoData: any) => {
        dispatch(savePhotoEvent(photoData))
    };

    const handleUpdateDonationMinMax = (value: any) => {
        dispatch(updatePaylinkDetails({ checkout_min_donation: value.min }))
        dispatch(updatePaylinkDetails({ checkout_max_donation: value.max }))
    }

    return (
        <>

            <div
                className='paylink-checkout-builder my-3'
                style={{
                    maxHeight: "1400px",
                    overflowY: "auto",
                    scrollbarWidth: "none"
                }}
            //style={{ overflowY: "auto", overflowX: "hidden", height: "93.5vh", }}
            >

                {
                    selectedPaylinkType === 'sell-products' ? (

                        <Row className='my-4 mx-2'>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ background: "none" }}
                                        className={activeTab == "1" ? "active" : ""}
                                        active={activeTab === "1"}
                                        onClick={() => {
                                            updateActiveTabs("1");
                                        }}
                                    >
                                        Checkout Page
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                <NavLink
                                    style={{ background: "none" }}
                                    className={activeTab == "2" ? "active" : ""}
                                    active={activeTab === "2"}
                                    onClick={() => {
                                        updateActiveTabs("2");
                                    }}
                                >
                                    Payment Page
                                </NavLink>
                            </NavItem> */}
                                <NavItem>
                                    <NavLink
                                        style={{ background: "none" }}
                                        className={activeTab == "3" ? "active" : ""}
                                        active={activeTab === "3"}
                                        onClick={() => {
                                            updateActiveTabs("3");
                                        }}
                                    >
                                        After Payment
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className='mt-4'>
                                <TabPane tabId="1">
                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Paylink details</span>
                                    </Row>
                                    <Row className=''>
                                        <FormGroup className="">
                                            <Label for='checkout_title' className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                <span className='' style={{ marginRight: "5px" }}>Title</span>
                                                <span className="mx-1" style={{ color: "red" }}>*</span>
                                                <IconToExplain popId="hov_1" content="This is the title of checkout page. . It is mandatory because it is used internally" direction="right" />
                                            </Label>
                                            <Input
                                                name="checkout_title"
                                                type="text"
                                                className="checkout_title"
                                                placeholder='Label of the paylink'
                                                value={paylinkDetails.checkout_title}
                                                onChange={handlePaylinkDetailsInputChange}

                                                valid={paylinkDetails.checkout_title && !checkoutFormErrors.title}
                                                invalid={checkoutFormErrors.title}
                                            />

                                            <FormFeedback>
                                                <TiWarningOutline color='#DF1B41' />
                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{checkoutFormErrors.title}</span>
                                            </FormFeedback>
                                        </FormGroup>

                                        <FormGroup className="">
                                            <Label for='checkout_description' className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                <span className='' style={{ marginRight: "5px" }}>Description</span>
                                                <span className="mx-1" style={{ color: "red" }}>*</span>
                                                <IconToExplain popId="hov_2" content="This is a description about the content of checkout page" direction="right" />
                                            </Label>
                                            <Input
                                                name="checkout_description"
                                                type="textarea"
                                                className="checkout_description no-resizable-textarea"
                                                id="checkout_description"
                                                placeholder='Give customers more details about the paylink.'
                                                onChange={handlePaylinkDetailsInputChange}
                                                rows="4"
                                                maxLength={300}
                                                value={paylinkDetails.checkout_description}
                                                valid={paylinkDetails.checkout_description && !checkoutFormErrors.description}
                                                invalid={checkoutFormErrors.description}

                                            />
                                            <FormFeedback>
                                                <TiWarningOutline color='#DF1B41' />
                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{checkoutFormErrors.description}</span>
                                            </FormFeedback>
                                        </FormGroup>
                                    </Row>

                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Product</span>
                                    </Row>
                                    <Row className=''>
                                        <Col sm={12}>
                                            <GrStatusInfo color='#687385' />
                                            <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                Select the the product from the list to add it to your checkout page or you can create a new product using the add button .
                                                You can add as many as you like to the checkout page .
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="nested-custom-select">
                                            <Label for='checkout-label' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                                                <span className='' style={{ marginRight: "5px" }}>Add product</span>
                                                <span className="mx-1" style={{ color: "red" }}>*</span>
                                                <IconToExplain popId="hov_3" content="You can add a product from the list and create a new one" direction="right" />
                                            </Label>
                                            <Input
                                                type="text"
                                                className="nested-select-input"
                                                placeholder='Find or add a product'
                                                onClick={() => setShowProductList(true)}
                                                onChange={(e) => {
                                                    setSearchTerm(e.target.value);
                                                }}
                                                value={searchTerm}
                                                style={{ width: "100%" }}
                                            />

                                            {showProductList && (
                                                <div
                                                    className="nested-options my-2 text-start"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        borderRadius: '5px',
                                                        //display: 'flex',
                                                        //alignItems: 'center',
                                                        //justifyContent: 'space-between',
                                                        border: "1px solid #ebeef1",
                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                        width: "100%"
                                                    }}
                                                    ref={nestedOptionsRef}
                                                >
                                                    <Button
                                                        className="add-button w-100"
                                                        style={{
                                                            backgroundColor: 'white',
                                                            borderRadius: '5px',
                                                            //display: 'flex',
                                                            //alignItems: 'center',
                                                            //justifyContent: 'space-between',
                                                            border: "1px solid #ebeef1",
                                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                                        }}
                                                        onClick={() => { toggleAddProductModal(); setAddProductModalType("product") }}
                                                    >
                                                        <FaPlus color='#434754' />
                                                        <span className='mx-2' style={{ color: "#434754", fontSize: "15px" }}>Add product</span>
                                                    </Button>

                                                    {availableProducts && availableProducts
                                                        .filter((product: any) => product.label.toLowerCase().includes(searchTerm.toLowerCase()))
                                                        .map((product: any) => (
                                                            <div
                                                                key={product.id}
                                                                className="option-content py-2"
                                                                onClick={() => handleSelectProduct(product)}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <div className="icon">{product.icon}</div>
                                                                <div className="text">
                                                                    <div className="label" style={{ color: "#414552", fontSize: "14px", fontWeight: "500" }}>{product.label}</div>
                                                                    <div className="description" style={{ color: "#414552", fontSize: "12px", fontWeight: "400" }}>{product.description}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </div>

                                        <div className="position-relative my-3">
                                            <div className="box px-3 py-4 rounded" style={{ border: "1px solid #e4e5e7" }}>
                                                {selectedProducts.map((product: any) => (
                                                    <Row className='my-2 py-2 product-list-selected'>
                                                        <Col sm="3">
                                                            {
                                                                product.picture ?
                                                                    <img
                                                                        src={product.picture ? product.picture : emptyProductImg}
                                                                        width="50px"
                                                                        height="50px"
                                                                        style={{ borderRadius: '10px' }}
                                                                    /> :
                                                                    <div style={{

                                                                        border: "1px solid #ddd",
                                                                        borderRadius: "15px",
                                                                        display: "inline-block"
                                                                    }}>
                                                                        <img
                                                                            src={product.picture ? product.picture : emptyProductImg}
                                                                            width="50px"
                                                                            height="50px"
                                                                            style={{ borderRadius: '10px' }}
                                                                        />
                                                                    </div>
                                                            }

                                                        </Col>
                                                        <Col className='d-flex align-items-center'>
                                                            <div className="text">
                                                                <div className="label" style={{ color: "#414552", fontSize: "14px", fontWeight: "500" }}>{product.label}</div>
                                                                <div className="description" style={{ color: "#414552", fontSize: "12px", fontWeight: "400" }}>{product.description}</div>
                                                            </div>
                                                        </Col>
                                                        <Col className='d-flex align-items-center justify-content-end'>
                                                            <a
                                                                onClick={() => handleRemoveProduct(product.id)}
                                                                title="Supprimer"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <TfiTrash color="#B42C2C" />
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                {selectedProducts.length == 0 && <span className=''>No product was added yet</span>}
                                            </div>
                                        </div>
                                    </Row>

                                    <Row className='d-flex justify-content-between'>
                                        <Col sm={5} className=''>
                                            <FormGroup check className=''>
                                                <Label check className="d-flex align-items-center">
                                                    {
                                                        (isEditMode && selectedDeliveryOption) ?
                                                            <Input
                                                                type="checkbox"
                                                                checked={true}
                                                                onChange={toggleChooseDelivery}
                                                            /> :
                                                            <Input
                                                                type="checkbox"
                                                                hecked={chooseDelivery}
                                                                onChange={toggleChooseDelivery}
                                                            />
                                                    }

                                                    <span className='' style={{ marginRight: "5px", marginLeft: "10px" }}>Add delivery</span> <IconToExplain popId="hov_4" content="you can add a delivery service to your checkout" direction="right" />
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col sm={4} className='text-end mx-3'>
                                            {
                                                (chooseDelivery || selectedDeliveryOption) &&
                                                <div className="custom-select">
                                                    <Button
                                                        className="select-button"
                                                        onClick={() => setShowDeliveryOptions(!showDeliveryOptions)}
                                                        style={{
                                                            backgroundColor: 'white',
                                                            borderRadius: '5px',
                                                            padding: '1%',
                                                            border: "1px solid #ebeef1",
                                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                            width: '200px',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}>

                                                        <span
                                                            style={{
                                                                color: "#434754",
                                                                fontSize: "15px",
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                textAlign: 'left'

                                                            }}
                                                        >
                                                            {selectedDeliveryOption ? selectedDeliveryOption.label :
                                                                "Choose a delivery option"
                                                            }


                                                        </span>

                                                        <TiArrowUnsorted className="arrow-icon" color="#434754" style={{ marginLeft: 'auto' }} />
                                                    </Button>
                                                    {showDeliveryOptions && (
                                                        <div className="options mt-2" style={{
                                                            backgroundColor: 'white',
                                                            borderRadius: '5px',
                                                            border: "1px solid #ebeef1",
                                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                            //width: "100%",
                                                            position: "absolute"
                                                        }}>
                                                            <Button
                                                                className="add-button w-100"
                                                                style={{
                                                                    backgroundColor: 'white',
                                                                    borderRadius: '5px',
                                                                    border: "1px solid #ebeef1",
                                                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                                                }}
                                                                onClick={() => { toggleAddProductModal(); setAddProductModalType("delivery") }}
                                                            >
                                                                <FaPlus color='#434754' className='mx-2' />
                                                                <span style={{ color: "#434754", fontSize: "15px" }}>Create another delivery</span>
                                                            </Button>
                                                            {availableDeliveryOptions.map((option: any) => (
                                                                <div
                                                                    key={option.id}
                                                                    className="option my-1 mx-1 text-start"
                                                                    onClick={() => { dispatch(setSelectedDeliveryOption(option)); setShowDeliveryOptions(!showDeliveryOptions) }}
                                                                >
                                                                    <div className="option-content">
                                                                        <div className="icon">{option.icon}</div>
                                                                        <div className="text">
                                                                            <div className="label" style={{ color: "#414552", fontSize: "14px", fontWeight: "500" }}>{option.label}</div>
                                                                            <div className="description" style={{ color: "#414552", fontSize: "12px", fontWeight: "400" }}>{option.description}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            }

                                        </Col>
                                    </Row>

                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Custom fields</span>
                                    </Row>
                                    <Row className=''>
                                        <Col sm={12}>
                                            <GrStatusInfo color='#687385' />
                                            <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                With cutom fields you can retrieve any alternatice informations you want from <br />
                                                the end-user or customer .
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Button
                                            className="outlined-button-with-icon mx-2"
                                            style={{ width: "96%" }}
                                            onClick={handleAddField}

                                        >
                                            <FaPlus className="mx-2" />
                                            Add Field
                                        </Button>
                                        {/* <pre>{JSON.stringify(optionalFields, null, 2)}</pre> */}
                                        {
                                            isEditMode ?
                                                <>
                                                    {optionalFields.map((field: any, index: any) => (<>
                                                        <OptionalField
                                                            key={index}
                                                            index={index}
                                                            label={field.label}
                                                            description={field.description}
                                                            type={field.type || field.field_type}
                                                            required={field.required}
                                                            options={field.options}
                                                        />
                                                    </>
                                                    ))}
                                                </> :
                                                <>
                                                    {optionalFields.map((field: any, index: any) => (<>
                                                        <OptionalField
                                                            key={index}
                                                            index={index}
                                                            label={field.label}
                                                            description={field.description}
                                                            type={field.type}
                                                            required={field.required}

                                                        />
                                                    </>
                                                    ))}
                                                </>
                                        }


                                    </Row>
                                    <Row className='my-4'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Choose Expiration Date</span>
                                    </Row>
                                    <Row className="">
                                        <FormGroup>
                                            <Label
                                                for="expiration_date"
                                                className=""
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "19px",
                                                    letterSpacing: "0em",
                                                    textAlign: "left",
                                                    color: "#697386",
                                                }}
                                            >
                                                Expiry date
                                            </Label>
                                            <PInput
                                                type="date"
                                                name="checkout_expiry_date"
                                                error={!!checkoutFormErrors.expiry_date}
                                                errorMessage={checkoutFormErrors.expiry_date}
                                                value={paylinkDetails.checkout_expiry_date === "" ? formatDate(actualDateExper) : paylinkDetails.checkout_expiry_date}
                                                onChange={handlePaylinkDetailsInputChange}
                                                placeholder="Expiration Date"
                                                minDate={minDateExp}
                                            />
                                        </FormGroup>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">

                                </TabPane>
                                <TabPane tabId="3">
                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", fontWeight: "700", fontFamily: 'apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu"', color: "#414552" }}>Confirmation page</span>
                                    </Row>
                                    <Row>
                                        <FormGroup tag="fieldset">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="confirmationPageToggle"
                                                        checked={confirmationPage}
                                                        onChange={handleToggleChange}
                                                    />
                                                    Show Confirmation Page
                                                </Label>
                                            </FormGroup>
                                            {
                                                confirmationPage &&
                                                <>
                                                    <FormGroup check className='mx-4 my-3'>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                checked={showAddCustomMsg}
                                                                onChange={toggleShowAddCustomMsg}
                                                            />
                                                            Add a custom message
                                                        </Label>
                                                    </FormGroup>
                                                    {
                                                        showAddCustomMsg && (
                                                            <FormGroup row className="mx-4 mb-5">
                                                                <Col sm={12}>
                                                                    <Input
                                                                        type="textarea"
                                                                        name="checkout_custom_returnmsg"
                                                                        id="checkout_custom_returnmsg"
                                                                        placeholder='Include any details you see fit, such as delivery information.'
                                                                        value={paylinkDetails.checkout_custom_returnmsg}
                                                                        onChange={handlePaylinkDetailsInputChange}
                                                                        rows={4}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                        )
                                                    }

                                                </>
                                            }

                                            <FormGroup check className='my-2'>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="confirmationPageToggle"
                                                        checked={!confirmationPage}
                                                        onChange={handleToggleChange}
                                                    />
                                                    Add an url to redirect to:
                                                </Label>
                                            </FormGroup>
                                            {!confirmationPage &&
                                                <>
                                                    <Row className='mx-2'>
                                                        <span style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>Redirect customers to your website.</span>
                                                    </Row>
                                                    <FormGroup row className="mx-2">
                                                        <Col sm={6}>
                                                            <Input
                                                                type='url'
                                                                value={paylinkDetails.checkout_redirect_url}
                                                                onChange={handlePaylinkDetailsInputChange}
                                                                name="checkout_redirect_url"
                                                                id="checkout_redirect_url"
                                                                placeholder='www.myurl.co'
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <Row className='mx-2 my-2'>
                                                        <Col sm={6}>
                                                            <GrStatusInfo color='#687385' />
                                                            <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                                This is the URL you gonna be redirected to after processing payment.
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </FormGroup>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>


                    ) : selectedPaylinkType === 'event-payment' ? (

                        <Row className='my-4 mx-2'>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ background: "none" }}
                                        className={activeTab == "1" ? "active" : ""}
                                        active={activeTab === "1"}
                                        onClick={() => {
                                            updateActiveTabs("1");
                                        }}
                                    >
                                        Checkout Page
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                <NavLink
                                    style={{ background: "none" }}
                                    className={activeTab == "2" ? "active" : ""}
                                    active={activeTab === "2"}
                                    onClick={() => {
                                        updateActiveTabs("2");
                                    }}
                                >
                                    Payment Page
                                </NavLink>
                            </NavItem> */}
                                <NavItem>
                                    <NavLink
                                        style={{ background: "none" }}
                                        className={activeTab == "3" ? "active" : ""}
                                        active={activeTab === "3"}
                                        onClick={() => {
                                            updateActiveTabs("3");
                                        }}
                                    >
                                        After Payment
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className='mt-4'>
                                <TabPane tabId="1">

                                    {!isEditMode &&
                                        <Row >
                                            <Col xs={5}>
                                                <span>Créer un événement: </span>
                                            </Col>
                                            <Col xs="3" style={{ marginRight: "0", padding: "0" }}>
                                                <FormGroup check>
                                                    <Input
                                                        name="ticket_price_option"
                                                        type="radio"
                                                        value="one"
                                                        checked={eventSingleMoreChoice === 'one'}
                                                        onChange={handleEventSingleMoreChoiceChange}
                                                    />
                                                    <Label check>un seul prix</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="4" style={{ marginRight: "0", padding: "0" }}>
                                                <FormGroup check>
                                                    <Input
                                                        name="ticket_price_option"
                                                        type="radio"
                                                        value="more"
                                                        checked={eventSingleMoreChoice === 'more'}
                                                        onChange={handleEventSingleMoreChoiceChange}
                                                    />
                                                    <Label check>plusieurs prix</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        eventSingleMoreChoice === "one" ?
                                            <>
                                                <Row className='mt-3'>
                                                    <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Détails de l'événement</span>
                                                </Row>
                                                <Row className=''>
                                                    <FormGroup className=''>
                                                        <Label for='checkout_image' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                                                            <span className='' style={{ marginRight: "5px" }}>Ajouter une photo</span>
                                                            <span className="mx-1" style={{ color: "red" }}>*</span>
                                                            <IconToExplain popId="hov_5" content="This is the image of checkout page" direction="right" />
                                                        </Label>

                                                        {(photoEvent !== null && photoEvent != "") ? (
                                                            <>
                                                                <img src={photoEvent} alt="upload image" width={200} height={200} className="img-display-after" style={{ marginLeft: "10%" }} />
                                                                <div style={{ marginRight: "2%" }}>
                                                                    <FcEditImage
                                                                        style={{ cursor: "pointer" }}
                                                                        size={25}
                                                                        onClick={(e) => {
                                                                            dispatch(savePhotoEvent(null))
                                                                            dispatch(setPhotoEdited(true))
                                                                        }}
                                                                    ></FcEditImage>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <DropZoneHolder
                                                                    name="checkout_image"
                                                                    handleUploadDocument={handleSavePhotoEvent}
                                                                    typeImage64={true}
                                                                    image={true}
                                                                />
                                                            </>


                                                        )
                                                        }
                                                    </FormGroup>
                                                    <FormGroup className="">
                                                        <Label for='checkout_title' className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                            <span className='' style={{ marginRight: "5px" }}>Nom de l'événement</span>
                                                            <span className="mx-1" style={{ color: "red" }}>*</span>
                                                            <IconToExplain popId="hov_1" content="This is the title of checkout page" direction="right" />
                                                        </Label>
                                                        <Input
                                                            name="checkout_title"
                                                            type="text"
                                                            className="checkout_title"
                                                            placeholder='Label of the paylink'
                                                            value={paylinkDetails.checkout_title}
                                                            onChange={handlePaylinkDetailsInputChange}
                                                            style={{ width: "100%" }}
                                                            valid={paylinkDetails.checkout_title && !checkoutFormErrors.title}
                                                            invalid={checkoutFormErrors.title}
                                                        />

                                                        <FormFeedback>
                                                            <TiWarningOutline color='#DF1B41' />
                                                            <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{checkoutFormErrors.title}</span>
                                                        </FormFeedback>
                                                    </FormGroup>

                                                    <FormGroup className="">
                                                        <Label for='checkout_description' className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                            <span className='' style={{ marginRight: "5px" }}>Description</span>
                                                            <span className="mx-1" style={{ color: "red" }}>*</span>
                                                            <IconToExplain popId="hov_2" content="This is a description about the content of checkout page" direction="right" />
                                                        </Label>
                                                        <Input
                                                            name="checkout_description"
                                                            type="textarea"
                                                            className="checkout_description no-resizable-textarea"
                                                            id="checkout_description"
                                                            placeholder='Give customers more details about the paylink.'
                                                            onChange={handlePaylinkDetailsInputChange}
                                                            rows={4}
                                                            value={paylinkDetails.checkout_description}
                                                            valid={paylinkDetails.checkout_description && !checkoutFormErrors.description}
                                                            invalid={checkoutFormErrors.description}
                                                        />
                                                        <FormFeedback>
                                                            <TiWarningOutline color='#DF1B41' />
                                                            <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{checkoutFormErrors.description}</span>
                                                        </FormFeedback>
                                                    </FormGroup>

                                                    <FormGroup className="">
                                                        <Label for='checkout_price' className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                            <span className='' style={{ marginRight: "5px" }}>Prix</span>
                                                            <span className="mx-1" style={{ color: "red" }}>*</span>
                                                            <IconToExplain popId="hov_1" content="This is the price of checkout page" direction="right" />
                                                        </Label>
                                                        <InputGroup>
                                                            <Input
                                                                name="checkout_price"
                                                                type="text"
                                                                className="checkout_price"
                                                                placeholder="0.000"
                                                                value={paylinkDetails.checkout_price === 0 ? "" : paylinkDetails.checkout_price}
                                                                onChange={handlePaylinkDetailsInputChange}
                                                                valid={paylinkDetails.checkout_price && !checkoutFormErrors.price}
                                                                invalid={checkoutFormErrors.price}
                                                                maxLength={10}
                                                            />
                                                            <InputGroupText style={{ backgroundColor: "white" }}>
                                                                TND
                                                            </InputGroupText>
                                                        </InputGroup>
                                                        <FormFeedback>
                                                            <TiWarningOutline color='#DF1B41' />
                                                            <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{checkoutFormErrors.price}</span>
                                                        </FormFeedback>
                                                    </FormGroup>

                                                </Row>

                                                <Row className=''>
                                                    <Col xs={9}>
                                                        <FormGroup check className=''>
                                                            <Label check className="d-flex align-items-center">
                                                                <Input
                                                                    type="checkbox"
                                                                    name="quantity"
                                                                    checked={eventQuantityAllowed}
                                                                    onChange={toggleEventQuantityAllowed}
                                                                />
                                                                <span className='' style={{ marginRight: "5px", marginLeft: "10px" }}>L'acheteur peut définir la quantité</span> <IconToExplain popId="hov_4" content="When enabled, you can specify the number of tickets available for sale. When disabled, the quantity will not be limited." direction="right" />
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="" xs={3}>
                                                        {
                                                            eventQuantityAllowed && (
                                                                <FormGroup >

                                                                    <div className="" style={{ maxWidth: "100px" }}>
                                                                        <Input
                                                                            name="checkout_event_stock"
                                                                            value={paylinkDetails.checkout_event_stock}
                                                                            type="number"
                                                                            onChange={handlePaylinkDetailsInputChange}
                                                                        //invalid={formValidation.qty}
                                                                        //valid={!formValidation.qty && product.qty != 0}
                                                                        /* onChange={(e) => {
                                                                            product.qty = e.target.value ? parseFloat(e.target.value)
                                                                                : 0; setProduct({ ...product }); console.log(typeof (product.label))
                                                                            if (product.qty === 0) {
                                                                                formValidation.qty = true
                                                                            } else {
                                                                                formValidation.qty = false
                                                                            }
                                                                            setFormValidation({ ...formValidation })
                                                                        }} */
                                                                        />
                                                                    </div>
                                                                    <FormFeedback className="mt-2">
                                                                        Ce champ est invalide
                                                                    </FormFeedback>

                                                                </FormGroup>
                                                            )
                                                        }

                                                    </Col>
                                                </Row>
                                                <Row className='my-3'>
                                                    <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Extras :</span>
                                                </Row>
                                                <Row className=''>
                                                    <Col sm={12}>
                                                        <GrStatusInfo color='#687385' />
                                                        <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                            You can add an extra options to the Event
                                                            or you can create a new product <br /> using the add button .
                                                            You can add as many as you like to the checkout page .
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className='my-1'>
                                                    <Button
                                                        className="outlined-button-with-icon mx-2"
                                                        onClick={handleAddExtraField}

                                                    >
                                                        <FaPlus className="mx-2" />
                                                        Add Extra Field
                                                    </Button>

                                                    {extraEventFields.map((field: any, index: any) => (
                                                        <>
                                                            <Row className='' key={index}>
                                                                <Col sm={3} className=''>
                                                                    <FormGroup>
                                                                        <Label check style={{ fontWeight: "bold", marginTop: "10px", }} for="optional_field_label" className="d-block">
                                                                            Libellé
                                                                            <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span>
                                                                        </Label>

                                                                        <Input
                                                                            key={index}
                                                                            id="label"
                                                                            name="label"
                                                                            type="text"
                                                                            value={field.label != "" ? field.label : ""}
                                                                            onChange={(e: any) => handleExtraFieldChange(index, 'label', e.target.value)}
                                                                            valid={extraFieldErrors[index] && extraFieldErrors[index].label == "" && field.label}
                                                                            invalid={extraFieldErrors[index] && extraFieldErrors[index].label != ""}
                                                                            className='mt-2'
                                                                        />

                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm={5}>
                                                                    <FormGroup>
                                                                        <Label check style={{ fontWeight: "bold", marginTop: "10px", }} for="optional_field_description" className="d-block">
                                                                            Description
                                                                            <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span>
                                                                        </Label>

                                                                        <Input
                                                                            key={index}
                                                                            id="description"
                                                                            name="description"
                                                                            value={field.description != "" ? field.description : ""}
                                                                            onChange={(e: any) => handleExtraFieldChange(index, 'description', e.target.value)}
                                                                            valid={extraFieldErrors[index] && extraFieldErrors[index].description == "" && field.description}
                                                                            invalid={extraFieldErrors[index] && extraFieldErrors[index].description != ""}
                                                                            type="textarea"
                                                                            rows="1"
                                                                            className='mt-2'
                                                                        />
                                                                    </FormGroup>

                                                                </Col>
                                                                <Col sm={3}>
                                                                    <FormGroup>
                                                                        <Label check style={{ fontWeight: "bold", marginTop: "10px", }} for="optional_field_label" className="d-block">
                                                                            Prix
                                                                            <span style={{ color: "#D83A52", marginLeft: "10px" }}> *</span>
                                                                        </Label>

                                                                        <Input
                                                                            key={index}
                                                                            id="price"
                                                                            name="price"
                                                                            value={field.price != "" ? field.price : 0}
                                                                            onChange={(e: any) => handleExtraFieldChange(index, 'price', e.target.value)}
                                                                            valid={extraFieldErrors[index] && extraFieldErrors[index].price == "" && field.price}
                                                                            invalid={extraFieldErrors[index] && extraFieldErrors[index].price != ""}
                                                                            type="text"
                                                                            className='mt-2'
                                                                        />

                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm={1} className='d-flex align-items-center'>
                                                                    <div style={{ marginTop: "50%", cursor: "pointer" }}>
                                                                        <a
                                                                            onClick={() => handleRemoveExtraField(index)}
                                                                            title="Supprimer"
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            <FaTrashAlt color="#B42C2C" size={20} />
                                                                        </a>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                        </>
                                                    ))}

                                                </Row>

                                                <Row className='my-3'>
                                                    <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Custom fields</span>
                                                </Row>
                                                <Row className=''>
                                                    <Col sm={12}>
                                                        <GrStatusInfo color='#687385' />
                                                        <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                            With cutom fields you can retrieve any alternatice informations you want from <br />
                                                            the end-user or customer .
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className='my-1'>
                                                    <Button
                                                        className="outlined-button-with-icon mx-2"
                                                        onClick={handleAddField}
                                                        style={{ width: "96%" }}
                                                    >
                                                        <FaPlus className="mx-2" />
                                                        Add Field
                                                    </Button>
                                                    {optionalFields.map((field: any, index: any) => (<>
                                                        <OptionalField key={index} index={index} label={field.label} description={field.description} type={field.type} required={field.required} />
                                                    </>
                                                    ))}

                                                </Row>
                                            </> :
                                            <>
                                                <Row className='my-3'>
                                                    <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Product</span>
                                                </Row>
                                                <Row className=''>
                                                    <Col sm={12}>
                                                        <GrStatusInfo color='#687385' />
                                                        <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                            Select the product from the list to add it to your checkout page
                                                            or you can create a new product using the add button .
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-2'>
                                                    <div className="nested-custom-select">
                                                        <Label for='checkout-label' className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                            <span className='' style={{ marginRight: "5px" }}>Add product</span>
                                                            <span className="mx-1" style={{ color: "red" }}>*</span>
                                                            <IconToExplain popId="hov_3" content="You can add a product from the list and create a new one" direction="right" />
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="nested-select-input"
                                                            placeholder='Find or add a product'
                                                            onClick={toggleShowProductList}
                                                            onChange={(e) => {
                                                                setSearchTerm(e.target.value);
                                                            }}
                                                            value={searchTerm}
                                                            style={{ width: "100%" }}
                                                        />

                                                        {showProductList && (
                                                            <div className="nested-options my-2 text-start"
                                                                style={{
                                                                    backgroundColor: 'white',
                                                                    borderRadius: '5px',
                                                                    //display: 'flex',
                                                                    //alignItems: 'center',
                                                                    //justifyContent: 'space-between',
                                                                    border: "1px solid #ebeef1",
                                                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                                    width: "100%"
                                                                }}

                                                                ref={nestedOptionsRef}
                                                            >
                                                                <Button
                                                                    className="add-button w-100"
                                                                    style={{
                                                                        backgroundColor: 'white',
                                                                        borderRadius: '5px',
                                                                        //display: 'flex',
                                                                        //alignItems: 'center',
                                                                        //justifyContent: 'space-between',
                                                                        border: "1px solid #ebeef1",
                                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                                                    }}
                                                                    onClick={() => { toggleAddProductModal(); setAddProductModalType("event") }}
                                                                >
                                                                    <FaPlus color='#434754' />
                                                                    <span className='mx-2' style={{ color: "#434754", fontSize: "15px" }}>Add event product</span>
                                                                </Button>
                                                                {availableProducts
                                                                    .filter((product: any) => product.label.toLowerCase().includes(searchTerm.toLowerCase()))
                                                                    .map((product: any) => (
                                                                        <div
                                                                            key={product.id}
                                                                            className="option-content"
                                                                            onClick={() => handleSelectProductEvent(product)}
                                                                        >
                                                                            <div className="icon">{product.icon}</div>
                                                                            <div className="text">
                                                                                <div className="label" style={{ color: "#414552", fontSize: "14px", fontWeight: "500" }}>{product.label}</div>
                                                                                <div className="description" style={{ color: "#414552", fontSize: "12px", fontWeight: "400" }}>{product.description}</div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="position-relative my-3">
                                                        <div className="box px-3 py-4 rounded" style={{ border: "1px solid #e4e5e7" }}>
                                                            {selectedProducts.map((product: any) => (
                                                                <Row className='my-2 product-list-selected'>
                                                                    <Col sm="3">
                                                                        {
                                                                            product.picture ?
                                                                                <img
                                                                                    src={product.picture ? product.picture : emptyProductImg}
                                                                                    width="50px"
                                                                                    height="50px"
                                                                                    style={{ borderRadius: '10px' }}
                                                                                /> :
                                                                                <div style={{

                                                                                    border: "1px solid #ddd",
                                                                                    borderRadius: "15px",
                                                                                    display: "inline-block"
                                                                                }}>
                                                                                    <img
                                                                                        src={product.picture ? product.picture : emptyProductImg}
                                                                                        width="50px"
                                                                                        height="50px"
                                                                                        style={{ borderRadius: '10px' }}
                                                                                    />
                                                                                </div>
                                                                        }
                                                                    </Col>
                                                                    <Col className='d-flex align-items-center'>
                                                                        <div className="text">
                                                                            <div className="label" style={{ color: "#414552", fontSize: "14px", fontWeight: "500" }}>{product.label}</div>
                                                                            <div className="description" style={{ color: "#414552", fontSize: "12px", fontWeight: "400" }}>{product.description}</div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className='d-flex align-items-center justify-content-end'>
                                                                        <a
                                                                            onClick={handleRemoveAllSelectedProducts}
                                                                            title="Supprimer"
                                                                            style={{ cursor: "pointer" }}
                                                                        >
                                                                            <TfiTrash color="#B42C2C" />
                                                                        </a>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                            {selectedProducts.length == 0 && <span className='pb-4'>No product was added yet</span>}
                                                        </div>
                                                    </div>
                                                </Row>

                                                <Row className='my-3'>
                                                    <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Custom fields</span>
                                                </Row>
                                                <Row className=''>
                                                    <Col sm={12}>
                                                        <GrStatusInfo color='#687385' />
                                                        <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                            With cutom fields you can retrieve any alternatice informations you want from <br />
                                                            the end-user or customer .
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className='my-1'>
                                                    <Button
                                                        className="outlined-button-with-icon mx-2"
                                                        onClick={handleAddField}
                                                        style={{ width: "96%" }}
                                                    >
                                                        <FaPlus className="mx-2" />
                                                        Add Field
                                                    </Button>
                                                    {optionalFields.map((field: any, index: any) => (<>
                                                        <OptionalField key={index} index={index} label={field.label} description={field.description} type={field.type} required={field.required} />
                                                    </>
                                                    ))}

                                                </Row>
                                            </>
                                    }

                                    <Row className='my-4'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Choose Expiration Date</span>
                                    </Row>
                                    <Row className="">
                                        <FormGroup>
                                            <Label
                                                for="expiration_date"
                                                className=""
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "19px",
                                                    letterSpacing: "0em",
                                                    textAlign: "left",
                                                    color: "#697386",
                                                }}
                                            >
                                                Expiry date
                                            </Label>
                                            <PInput
                                                type="date"
                                                name="checkout_expiry_date"
                                                error={!!checkoutFormErrors.expiry_date}
                                                errorMessage={checkoutFormErrors.expiry_date}
                                                value={paylinkDetails.checkout_expiry_date === "" ? formatDate(actualDateExper) : paylinkDetails.checkout_expiry_date}
                                                onChange={handlePaylinkDetailsInputChange}
                                                placeholder="Expiration Date"
                                                minDate={minDateExp}
                                            />
                                        </FormGroup>
                                    </Row>

                                </TabPane>
                                <TabPane tabId="2">

                                </TabPane>
                                <TabPane tabId="3">
                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", fontWeight: "700", fontFamily: 'apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu"', color: "#414552" }}>Confirmation page</span>
                                    </Row>
                                    <Row>
                                        <FormGroup tag="fieldset">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="confirmationPageToggle"
                                                        checked={confirmationPage}
                                                        onChange={handleToggleChange}
                                                    />
                                                    Show Confirmation Page
                                                </Label>
                                            </FormGroup>
                                            {
                                                confirmationPage &&
                                                <>
                                                    <FormGroup check className='mx-4 my-3'>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                checked={confirmationPageCustomMessage}
                                                                onChange={handleToggleCustomMessage}
                                                            />
                                                            Replace default with custom message
                                                        </Label>
                                                    </FormGroup>
                                                    {confirmationPageCustomMessage &&
                                                        <FormGroup row className="mx-4 mb-5">
                                                            <Col sm={12}>
                                                                <Input
                                                                    type="textarea"
                                                                    name="additionalComments"
                                                                    id="additionalComments"
                                                                    placeholder='Include any details you see fit, such as delivery information.'
                                                                    //value={additionalComments}
                                                                    //onChange={handleTextareaChange}
                                                                    rows={4}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    }

                                                </>
                                            }

                                            <FormGroup check className='my-2'>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="confirmationPageToggle"
                                                        checked={!confirmationPage}
                                                        onChange={handleToggleChange}
                                                    />
                                                    Don't show confirmation page
                                                </Label>
                                            </FormGroup>
                                            {!confirmationPage &&
                                                <>
                                                    <Row className='mx-2'>
                                                        <span style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>Redirect customers to your website.</span>
                                                    </Row>
                                                    <FormGroup row className="mx-2">
                                                        <Col sm={6}>
                                                            <LinkInputWithPopover />
                                                        </Col>
                                                    </FormGroup>
                                                    {/* <Row className='mx-2'>
                                                    <Col sm={6}>
                                                        <TiWarningOutline color='#DF1B41' />
                                                        <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>Enter a valid website url.</span>

                                                    </Col>
                                                </Row> */}
                                                    <Row className='mx-2 my-2'>
                                                        <Col sm={6}>
                                                            <GrStatusInfo color='#687385' />
                                                            <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                                This is the URL you gonna be redirected to after processing payment.
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </FormGroup>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>

                    ) : selectedPaylinkType === 'collect-payment' ? (
                        <div>
                            <h1>Collect payment</h1>

                        </div>
                    ) : selectedPaylinkType === 'accept-donation' ? (
                        <Row className='my-4 mx-2'>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ background: "none" }}
                                        className={activeTab == "1" ? "active" : ""}
                                        active={activeTab === "1"}
                                        onClick={() => {
                                            updateActiveTabs("1");
                                        }}
                                    >
                                        Checkout Page
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                <NavLink
                                    style={{ background: "none" }}
                                    className={activeTab == "2" ? "active" : ""}
                                    active={activeTab === "2"}
                                    onClick={() => {
                                        updateActiveTabs("2");
                                    }}
                                >
                                    Payment Page
                                </NavLink>
                            </NavItem> */}
                                <NavItem>
                                    <NavLink
                                        style={{ background: "none" }}
                                        className={activeTab == "3" ? "active" : ""}
                                        active={activeTab === "3"}
                                        onClick={() => {
                                            updateActiveTabs("3");
                                        }}
                                    >
                                        After Payment
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className='mt-4'>
                                <TabPane tabId="1">
                                    <Row>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Paylink details</span>
                                    </Row>
                                    <Row className='my-3'>

                                        <FormGroup className="mx-2">
                                            <Label for='checkout_title' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                                                <span className='' style={{ marginRight: "5px" }}>Title</span>
                                                <IconToExplain popId="hov_1" content="This is the title of the checkout page. It is mandatory because it is used internally." direction="right" />
                                            </Label>
                                            <Input
                                                name="checkout_title"
                                                type="text"
                                                className="checkout_title"
                                                placeholder='Label of the paylink'
                                                value={paylinkDetails.checkout_title}
                                                onChange={handlePaylinkDetailsInputChange}
                                                style={{ width: "100%" }}
                                                valid={paylinkDetails.checkout_title && !checkoutFormErrors.title}
                                                invalid={checkoutFormErrors.title}
                                            />

                                            <FormFeedback>
                                                <TiWarningOutline color='#DF1B41' />
                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{checkoutFormErrors.title}</span>
                                            </FormFeedback>
                                        </FormGroup>

                                        <FormGroup className="mx-2">
                                            <Label for='checkout_description' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                                                <span className='' style={{ marginRight: "5px" }}>Description</span>
                                                <IconToExplain popId="hov_2" content="This is a description about the content of checkout page" direction="right" />
                                            </Label>
                                            <Input
                                                name="checkout_description"
                                                type="textarea"
                                                className="checkout_description no-resizable-textarea"
                                                id="checkout_description"
                                                placeholder='Give customers more details about the paylink.'
                                                onChange={handlePaylinkDetailsInputChange}
                                                rows={4}
                                                value={paylinkDetails.checkout_description}
                                                valid={paylinkDetails.checkout_description && !checkoutFormErrors.description}
                                                invalid={checkoutFormErrors.description}
                                            />
                                            <FormFeedback>
                                                <TiWarningOutline color='#DF1B41' />
                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{checkoutFormErrors.description}</span>
                                            </FormFeedback>
                                        </FormGroup>

                                        <FormGroup className='mx-2'>
                                            <Label for='checkout_image' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                                                <span className='' style={{ marginRight: "5px" }}>Add a picture</span>
                                                <IconToExplain popId="hov_5" content="This is the image of checkout page" direction="right" />
                                            </Label>

                                            {(photoDonation !== null && photoDonation != "") ? (
                                                <>
                                                    <img src={photoDonation} alt="upload image" width={200} height={200} className="img-display-after" style={{ marginLeft: "2%", borderRadius: "5px", }} />
                                                    <div style={{ marginLeft: "2%" }}>
                                                        <FcEditImage
                                                            style={{ cursor: "pointer" }}
                                                            size={25}
                                                            onClick={(e) => {
                                                                dispatch(savePhotoDonation(null))
                                                                dispatch(setPhotoEdited(true))
                                                            }
                                                            }
                                                            title='edit the image'
                                                        ></FcEditImage>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <DropZoneHolder
                                                        name="checkout_image"
                                                        handleUploadDocument={handleSavePhotoDonation}
                                                        typeImage64={true}
                                                    />

                                                </>


                                            )
                                            }
                                        </FormGroup>

                                        <FormGroup check className='mx-3 my-3'>
                                            <Label check className="d-flex align-items-center">
                                                <Input
                                                    type="checkbox"
                                                    checked={showLimitDonation}
                                                    onChange={toggleSetLimitDonation}
                                                />
                                                <span className='' style={{ marginRight: "5px", marginLeft: "10px" }}>Limit the range of amount</span> <IconToExplain popId="hov_6" content="you limit the range of donation's amount to be deposed" direction="right" />
                                            </Label>
                                        </FormGroup>
                                        {
                                            showLimitDonation &&
                                            <>
                                                <Row className='mx-2'>
                                                    <Col>
                                                        <Input
                                                            placeholder="min"
                                                            name="minAmount"
                                                            value={paylinkDetails.checkout_min_donation}
                                                            style={{ height: "40px important", width: "200px" }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Input
                                                            placeholder="max"
                                                            name="maxAmount"
                                                            value={paylinkDetails.checkout_max_donation}
                                                            style={{ height: "40px important" }}
                                                        />
                                                    </Col>
                                                </Row>


                                            </>
                                        }

                                    </Row>
                                    <Row className='my-3'>
                                        <Col>
                                            <FormGroup check className='mx-1 my-3'>
                                                <Label check className="d-flex align-items-center">
                                                    <Input
                                                        type="checkbox"
                                                        checked={showSetupGoalDonation}
                                                        onChange={toggleShowSetupGoalDonation}
                                                    />
                                                    <span className='' style={{ marginRight: "5px", marginLeft: "10px" }}>Set the goal to attend:</span> <IconToExplain popId="hov_7" content="push the user to donate more and more by displaying the donation's goal" direction="right" />
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col className="d-flex justify-content-end my-2">
                                            {
                                                showSetupGoalDonation &&
                                                <>
                                                    <FormGroup style={{ width: "100px" }}>
                                                        <Input
                                                            value={paylinkDetails.checkout_donation_goal}
                                                            name="donation_goal"
                                                            type="number"
                                                            onChange={(e) =>
                                                                dispatch(updatePaylinkDetails({ checkout_donation_goal: parseInt(e.target.value) }))
                                                            }
                                                        />
                                                        <FormFeedback className="mt-2">
                                                            Ce champ est invalide
                                                        </FormFeedback>

                                                    </FormGroup>
                                                    <span className='mx-2 my-2'>
                                                        TND
                                                    </span>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Custom fields</span>
                                    </Row>
                                    <Row className='mx-2 my-3'>
                                        <Col sm={12}>
                                            <GrStatusInfo color='#687385' />
                                            <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                With cutom fields you can retrieve any alternatice informations you want from <br />
                                                the end-user or customer .
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className='my-3 mx-2'>
                                        <Button
                                            className="outlined-button-with-icon mx-2"
                                            onClick={handleAddField}
                                            style={{ width: "96%" }}
                                        >
                                            <FaPlus className="mx-2" />
                                            Add Field
                                        </Button>
                                        {optionalFields.map((field: any, index: any) => (<>
                                            <OptionalField key={index} index={index} label={field.label} description={field.description} type={field.type} required={field.required} />
                                        </>
                                        ))}

                                    </Row>
                                    <Row className='my-4'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Choose Expiration Date</span>
                                    </Row>
                                    <Row className="">
                                        <FormGroup>
                                            <Label
                                                for="expiration_date"
                                                className=""
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "19px",
                                                    letterSpacing: "0em",
                                                    textAlign: "left",
                                                    color: "#697386",
                                                }}
                                            >
                                                Expiry date
                                            </Label>
                                            <PInput
                                                type="date"
                                                name="checkout_expiry_date"
                                                error={!!checkoutFormErrors.expiry_date}
                                                errorMessage={checkoutFormErrors.expiry_date}
                                                value={paylinkDetails.checkout_expiry_date === "" ? formatDate(actualDateExper) : paylinkDetails.checkout_expiry_date}
                                                onChange={handlePaylinkDetailsInputChange}
                                                placeholder="Expiration Date"
                                                minDate={minDateExp}
                                            />
                                        </FormGroup>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">

                                </TabPane>
                                <TabPane tabId="3">
                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", fontWeight: "700", fontFamily: 'apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu"', color: "#414552" }}>Confirmation page</span>
                                    </Row>
                                    <Row>
                                        <FormGroup tag="fieldset">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="confirmationPageToggle"
                                                        checked={confirmationPage}
                                                        onChange={handleToggleChange}
                                                    />
                                                    Show Confirmation Page
                                                </Label>
                                            </FormGroup>
                                            {
                                                confirmationPage &&
                                                <>
                                                    <FormGroup check className='mx-4 my-3'>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                checked={showAddCustomMsg}
                                                                onChange={toggleShowAddCustomMsg}
                                                            />
                                                            Add a custom message
                                                        </Label>
                                                    </FormGroup>
                                                    {
                                                        showAddCustomMsg && (
                                                            <FormGroup row className="mx-4 mb-5">
                                                                <Col sm={12}>
                                                                    <Input
                                                                        type="textarea"
                                                                        name="checkout_custom_returnmsg"
                                                                        id="checkout_custom_returnmsg"
                                                                        placeholder='Include any details you see fit, such as delivery information.'
                                                                        value={paylinkDetails.checkout_custom_returnmsg}
                                                                        onChange={handlePaylinkDetailsInputChange}
                                                                        rows={4}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                        )
                                                    }
                                                </>
                                            }

                                            <FormGroup check className='my-2'>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="confirmationPageToggle"
                                                        checked={!confirmationPage}
                                                        onChange={handleToggleChange}
                                                    />
                                                    Don't show confirmation page
                                                </Label>
                                            </FormGroup>
                                            {!confirmationPage &&
                                                <>
                                                    <Row className='mx-2'>
                                                        <span style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>Redirect customers to your website.</span>
                                                    </Row>
                                                    <FormGroup row className="mx-2">
                                                        <Col sm={6}>
                                                            <LinkInputWithPopover />
                                                        </Col>
                                                    </FormGroup>
                                                    {/* <Row className='mx-2'>
                                                    <Col sm={6}>
                                                        <TiWarningOutline color='#DF1B41' />
                                                        <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>Enter a valid website url.</span>

                                                    </Col>
                                                </Row> */}
                                                    <Row className='mx-2 my-2'>
                                                        <Col sm={6}>
                                                            <GrStatusInfo color='#687385' />
                                                            <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                                This is the URL you gonna be redirected to after processing payment.
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </FormGroup>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>
                    ) : selectedPaylinkType === 'create_subscriptions' ? (
                        <Row className='my-4 mx-2'>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ background: "none" }}
                                        className={activeTab == "1" ? "active" : ""}
                                        active={activeTab === "1"}
                                        onClick={() => {
                                            updateActiveTabs("1");
                                        }}
                                    >
                                        Checkout Page
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ background: "none" }}
                                        className={activeTab == "3" ? "active" : ""}
                                        active={activeTab === "3"}
                                        onClick={() => {
                                            updateActiveTabs("3");
                                        }}
                                    >
                                        After Payment
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab} className='mt-4'>
                                <TabPane tabId="1">
                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Paylink details</span>
                                    </Row>
                                    <Row className=''>
                                        <FormGroup className="">
                                            <Label for='checkout_title' className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                <span className='' style={{ marginRight: "5px" }}>Title</span>
                                                <span className="mx-1" style={{ color: "red" }}>*</span>
                                                <IconToExplain popId="hov_1" content="This is the title of checkout page. . It is mandatory because it is used internally" direction="right" />
                                            </Label>
                                            <Input
                                                name="checkout_title"
                                                type="text"
                                                className="checkout_title"
                                                placeholder='Label of the paylink'
                                                value={paylinkDetails.checkout_title}
                                                onChange={handlePaylinkDetailsInputChange}

                                                valid={paylinkDetails.checkout_title && !checkoutFormErrors.title}
                                                invalid={checkoutFormErrors.title}
                                            />

                                            <FormFeedback>
                                                <TiWarningOutline color='#DF1B41' />
                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{checkoutFormErrors.title}</span>
                                            </FormFeedback>
                                        </FormGroup>

                                        <FormGroup className="">
                                            <Label for='checkout_description' className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                <span className='' style={{ marginRight: "5px" }}>Description</span>
                                                <span className="mx-1" style={{ color: "red" }}>*</span>
                                                <IconToExplain popId="hov_2" content="This is a description about the content of checkout page" direction="right" />
                                            </Label>
                                            <Input
                                                name="checkout_description"
                                                type="textarea"
                                                className="checkout_description no-resizable-textarea"
                                                id="checkout_description"
                                                placeholder='Give customers more details about the paylink.'
                                                onChange={handlePaylinkDetailsInputChange}
                                                rows="4"
                                                maxLength={300}
                                                value={paylinkDetails.checkout_description}
                                                valid={paylinkDetails.checkout_description && !checkoutFormErrors.description}
                                                invalid={checkoutFormErrors.description}

                                            />
                                            <FormFeedback>
                                                <TiWarningOutline color='#DF1B41' />
                                                <span className='text-danger mx-2' style={{ fontSize: "12px", fontWeight: "400" }}>{checkoutFormErrors.description}</span>
                                            </FormFeedback>
                                        </FormGroup>
                                    </Row>
                                    <Row className='d-flex justify-content-between my-1'>
                                        <Col>
                                            <Label for='checkout_title' className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                <span className='' style={{ marginRight: "5px" }}>The number of cycles is defined by the merchant </span>
                                            </Label>
                                        </Col>
                                        <Col sm={2} className="d-flex justify-content-end">
                                            <Input
                                                type="checkbox"
                                                //checked={true}
                                                onChange={toggleShowCustomCycles}
                                            />
                                        </Col>
                                        {/* <Col className="d-flex justify-content-end">
                                            <div className="d-inline-flex align-items-center">
                                                <FormGroup check className="me-3 d-inline-flex align-items-center">
                                                    <Input
                                                        name="ticket_price_option"
                                                        type="radio"
                                                        value="one"
                                                        checked={!showCustomCycle}
                                                        onChange={() => setShowCustomCycle(false)}
                                                    />
                                                    <Label check className="ms-1">Unlimited</Label>
                                                </FormGroup>
                                                <FormGroup check className="d-inline-flex align-items-center">
                                                    <Input
                                                        name="ticket_price_option"
                                                        type="radio"
                                                        value="more"
                                                        checked={showCustomCycle}
                                                        onChange={() => setShowCustomCycle(true)}
                                                    />
                                                    <Label check className="ms-1">Custom cycles</Label>
                                                </FormGroup>
                                            </div>
                                        </Col> */}
                                    </Row>
                                    {
                                        showCustomCycle &&
                                        <Row className='d-flex justify-content-between my-1'>
                                            <Col className="d-flex align-items-center">
                                                <Label for="checkout_title" className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
                                                    <span style={{ marginRight: "5px" }}>Select the number of cycles </span>
                                                </Label>
                                            </Col>
                                            <Col className="d-flex justify-content-end" sm={3}>
                                                <Input
                                                    name="checkout_frequency_cycle"
                                                    type="number"
                                                    className="checkout_frequency_cycle"
                                                    placeholder=""
                                                    defaultValue={1}
                                                    min={1}
                                                    max={100}
                                                    value={paylinkDetails.checkout_frequency_cycle}
                                                    onChange={handlePaylinkDetailsInputChange}
                                                />
                                                {/* <Input
                                                type="select"
                                                name="cycle_count"
                                                id="cycle_count"
                                                style={{ maxWidth: "100px" }}
                                            >
                                                {Array.from({ length: 10 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                ))}
                                            </Input> */}
                                            </Col>

                                        </Row>
                                    }


                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Subscription</span>
                                    </Row>
                                    <Row className=''>
                                        <Col sm={12}>
                                            <GrStatusInfo color='#687385' />
                                            <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                Select the the subscription from the list to add it to your checkout page or you can create a new one using the add button .
                                                You can add as many as you like to the checkout page .
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="nested-custom-select">
                                            <Label for='checkout-label' className="d-flex align-items-center" style={{ fontWeight: "bold", marginTop: "10px", }}>
                                                <span className='' style={{ marginRight: "5px" }}>Add subscription</span>
                                                <span className="mx-1" style={{ color: "red" }}>*</span>
                                                <IconToExplain popId="hov_3" content="You can add a product from the list and create a new one" direction="right" />
                                            </Label>
                                            <Input
                                                type="text"
                                                className="nested-select-input"
                                                placeholder='Find or add a subscription'
                                                onClick={() => setShowProductList(true)}
                                                onChange={(e) => {
                                                    setSearchTerm(e.target.value);
                                                }}
                                                value={searchTerm}
                                                style={{ width: "100%" }}
                                            />

                                            {showProductList && (
                                                <div
                                                    className="nested-options my-2 text-start"
                                                    style={{
                                                        backgroundColor: 'white',
                                                        borderRadius: '5px',
                                                        //display: 'flex',
                                                        //alignItems: 'center',
                                                        //justifyContent: 'space-between',
                                                        border: "1px solid #ebeef1",
                                                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                        width: "100%"
                                                    }}
                                                    ref={nestedOptionsRef}
                                                >
                                                    <Button
                                                        className="add-button w-100"
                                                        style={{
                                                            backgroundColor: 'white',
                                                            borderRadius: '5px',
                                                            //display: 'flex',
                                                            //alignItems: 'center',
                                                            //justifyContent: 'space-between',
                                                            border: "1px solid #ebeef1",
                                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                                                        }}
                                                        onClick={() => { toggleAddProductModal(); setAddProductModalType("product") }}
                                                    >
                                                        <FaPlus color='#434754' />
                                                        <span className='mx-2' style={{ color: "#434754", fontSize: "15px" }}>Add subscription</span>
                                                    </Button>

                                                    {availableProducts && availableProducts
                                                        .filter((product: any) => product.label.toLowerCase().includes(searchTerm.toLowerCase()))
                                                        .map((product: any) => (
                                                            <div
                                                                key={product.id}
                                                                className="option-content py-2"
                                                                onClick={() => handleSelectProduct(product)}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <div className="icon">{product.icon}</div>
                                                                <div className="text">
                                                                    <div className="label" style={{ color: "#414552", fontSize: "14px", fontWeight: "500" }}>{product.label}</div>
                                                                    <div className="description" style={{ color: "#414552", fontSize: "12px", fontWeight: "400" }}>{product.description}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </div>

                                        <div className="position-relative my-3">
                                            <div className="box px-3 py-4 rounded" style={{ border: "1px solid #e4e5e7" }}>
                                                {selectedProducts.map((product: any) => (
                                                    <Row className='my-2 py-2 product-list-selected'>
                                                        <Col sm="3">
                                                            {
                                                                product.picture ?
                                                                    <img
                                                                        src={product.picture ? product.picture : emptyProductImg}
                                                                        width="50px"
                                                                        height="50px"
                                                                        style={{ borderRadius: '10px' }}
                                                                    /> :
                                                                    <div style={{

                                                                        border: "1px solid #ddd",
                                                                        borderRadius: "15px",
                                                                        display: "inline-block"
                                                                    }}>
                                                                        <img
                                                                            src={product.picture ? product.picture : emptyProductImg}
                                                                            width="50px"
                                                                            height="50px"
                                                                            style={{ borderRadius: '10px' }}
                                                                        />
                                                                    </div>
                                                            }

                                                        </Col>
                                                        <Col className='d-flex align-items-center'>
                                                            <div className="text">
                                                                <div className="label" style={{ color: "#414552", fontSize: "14px", fontWeight: "500" }}>{product.label}</div>
                                                                <div className="description" style={{ color: "#414552", fontSize: "12px", fontWeight: "400" }}>{product.description}</div>
                                                            </div>
                                                        </Col>
                                                        <Col className='d-flex align-items-center justify-content-end'>
                                                            <a
                                                                onClick={() => handleRemoveProduct(product.id)}
                                                                title="Supprimer"
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <TfiTrash color="#B42C2C" />
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                {selectedProducts.length == 0 && <span className=''>No subscription was added yet</span>}
                                            </div>
                                        </div>
                                    </Row>
                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Custom fields</span>
                                    </Row>
                                    <Row className=''>
                                        <Col sm={12}>
                                            <GrStatusInfo color='#687385' />
                                            <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                With cutom fields you can retrieve any alternatice informations you want from <br />
                                                the end-user or customer .
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className='my-1'>
                                        <Button
                                            className="outlined-button-with-icon mx-2"
                                            style={{ width: "96%" }}
                                            onClick={handleAddField}

                                        >
                                            <FaPlus className="mx-2" />
                                            Add Field
                                        </Button>
                                        {/* <pre>{JSON.stringify(optionalFields, null, 2)}</pre> */}
                                        {
                                            isEditMode ?
                                                <>
                                                    {optionalFields.map((field: any, index: any) => (<>
                                                        <OptionalField
                                                            key={index}
                                                            index={index}
                                                            label={field.label}
                                                            description={field.description}
                                                            type={field.type || field.field_type}
                                                            required={field.required}
                                                            options={field.options}
                                                        />
                                                    </>
                                                    ))}
                                                </> :
                                                <>
                                                    {optionalFields.map((field: any, index: any) => (<>
                                                        <OptionalField
                                                            key={index}
                                                            index={index}
                                                            label={field.label}
                                                            description={field.description}
                                                            type={field.type}
                                                            required={field.required}

                                                        />
                                                    </>
                                                    ))}
                                                </>
                                        }


                                    </Row>
                                    <Row className='my-4'>
                                        <span style={{ fontSize: "20px", color: "#213A7D", fontWeight: "700", fontFamily: "Inter" }}>Choose Expiration Date</span>
                                    </Row>
                                    <Row className="">
                                        <FormGroup>
                                            <Label
                                                for="expiration_date"
                                                className=""
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "19px",
                                                    letterSpacing: "0em",
                                                    textAlign: "left",
                                                    color: "#697386",
                                                }}
                                            >
                                                Expiry date
                                            </Label>
                                            <PInput
                                                type="date"
                                                name="checkout_expiry_date"
                                                error={!!checkoutFormErrors.expiry_date}
                                                errorMessage={checkoutFormErrors.expiry_date}
                                                value={paylinkDetails.checkout_expiry_date === "" ? formatDate(actualDateExper) : paylinkDetails.checkout_expiry_date}
                                                onChange={handlePaylinkDetailsInputChange}
                                                placeholder="Expiration Date"
                                                minDate={minDateExp}
                                            />
                                        </FormGroup>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">

                                </TabPane>
                                <TabPane tabId="3">
                                    <Row className='my-3'>
                                        <span style={{ fontSize: "20px", fontWeight: "700", fontFamily: 'apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu"', color: "#414552" }}>Confirmation page</span>
                                    </Row>
                                    <Row>
                                        <FormGroup tag="fieldset">
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="confirmationPageToggle"
                                                        checked={confirmationPage}
                                                        onChange={handleToggleChange}
                                                    />
                                                    Show Confirmation Page
                                                </Label>
                                            </FormGroup>
                                            {
                                                confirmationPage &&
                                                <>
                                                    <FormGroup check className='mx-4 my-3'>
                                                        <Label check>
                                                            <Input
                                                                type="checkbox"
                                                                checked={showAddCustomMsg}
                                                                onChange={toggleShowAddCustomMsg}
                                                            />
                                                            Add a custom message
                                                        </Label>
                                                    </FormGroup>
                                                    {
                                                        showAddCustomMsg && (
                                                            <FormGroup row className="mx-4 mb-5">
                                                                <Col sm={12}>
                                                                    <Input
                                                                        type="textarea"
                                                                        name="checkout_custom_returnmsg"
                                                                        id="checkout_custom_returnmsg"
                                                                        placeholder='Include any details you see fit, such as delivery information.'
                                                                        value={paylinkDetails.checkout_custom_returnmsg}
                                                                        onChange={handlePaylinkDetailsInputChange}
                                                                        rows={4}
                                                                    />
                                                                </Col>
                                                            </FormGroup>
                                                        )
                                                    }

                                                </>
                                            }

                                            <FormGroup check className='my-2'>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="confirmationPageToggle"
                                                        checked={!confirmationPage}
                                                        onChange={handleToggleChange}
                                                    />
                                                    Add an url to redirect to:
                                                </Label>
                                            </FormGroup>
                                            {!confirmationPage &&
                                                <>
                                                    <Row className='mx-2'>
                                                        <span style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>Redirect customers to your website.</span>
                                                    </Row>
                                                    <FormGroup row className="mx-2">
                                                        <Col sm={6}>
                                                            <Input
                                                                type='url'
                                                                value={paylinkDetails.checkout_redirect_url}
                                                                onChange={handlePaylinkDetailsInputChange}
                                                                name="checkout_redirect_url"
                                                                id="checkout_redirect_url"
                                                                placeholder='www.myurl.co'
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <Row className='mx-2 my-2'>
                                                        <Col sm={6}>
                                                            <GrStatusInfo color='#687385' />
                                                            <span className='mx-2' style={{ color: "#687385", fontSize: "12px", fontWeight: "400" }}>
                                                                This is the URL you gonna be redirected to after processing payment.
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </FormGroup>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Row>
                    )
                        : (
                            <div className="d-flex flex-column align-items-center mt-5">
                                <img
                                    src={emptyTableImage}
                                    alt="Empty Table"
                                    style={{ width: "500px", height: "500px", marginTop: "-5%" }}
                                />
                                <span
                                    className="mt-2"
                                    style={{
                                        fontFamily: "Inter",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "20px",
                                        lineHeight: "24px",
                                        textAlign: "center",
                                        color: "#034DA3",
                                    }}
                                >
                                    No paylink type was choosed YET
                                </span>
                            </div>
                        )
                }

            </div >
            <AddProduct
                onSendData={() => { handleChildData() }}
                type={addProductModalType}
                showModalFormProduct={addProductModal}
                toggle={() => toggleAddProductModal()} />
        </>
    );
}

export default CheckoutGenerator;